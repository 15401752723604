const facialBiometrics = {
  title: 'Biometría facial',
  documentType: {
    title: '¿Qué documento deseas usar?',
    subtitle: 'Elige el que tenga la foto más reciente',
    iAgreeTo: 'Autorizo la @:steps.facialBiometrics.title',
    action: 'Tomar foto',
    officialDocuments: {
      cnh: {
        title: 'CNH',
        text: 'Habilitación',
      },
      rg: {
        title: 'RG',
        text: 'Identidad',
      },
    },
  },
  carousel: {
    step1: {
      title: '@:steps.facialBiometrics.title',
      text: 'Vamos a tomar una foto tuya y de tu documento oficial para confirmar tu identidad',
    },
    step2: {
      title: '@:steps.liveness.carousel.step2.title',
      text: {
        first: '@:steps.liveness.carousel.step2.text.first',
        second: '@:steps.liveness.carousel.step2.text.second',
        third: '@:steps.liveness.carousel.step2.text.third',
        fourth: '@:steps.liveness.carousel.step2.text.fourth',
      },
    },
    step3: {
      title: 'Activa tu cámara',
      text: 'Cuando se te solicite, toca en permitir para iniciar la captura de la imagen',
    },
  },
  helpModal: {
    title: '@:steps.facialBiometrics.title',
    whatIs: {
      title: '¿Qué es?',
      description:
        'Es una forma de validar la identidad de quien firma al comparar dos imágenes: la foto selfie y la foto del documento.',
    },
    why: {
      title: '¿Por qué firmar con @:steps.facialBiometrics.title?',
      description:
        'Esta autenticación reduce el riesgo de fraude, haciendo que la firma electrónica sea aún más segura.',
    },
    learnMore: {
      base: '¿Quieres saber más? Consulta nuestra {0} y {1}',
      privacyPolicy: 'Política de Privacidad',
      helpCenter: 'Centro de Ayuda.',
    },
    authMethod: '*El remitente del documento eligió este método de autenticación',
  },
  cameraError: {
    unsupportedBrowser: 'Navegador no compatible.',
    cameraMissing: 'Cámara no encontrada.',
    cameraBusy: 'Cámara en uso por otra aplicación.',
    cameraBlocked: 'Permiso para la cámara denegado.',
    unknownCameraError: 'Ocurrió un error al iniciar la cámara.',
  },
  capture: {
    title: {
      rg: {
        front: '@:steps.documentDetector.capture.title.rg.front',
        back: '@:steps.documentDetector.capture.title.rg.back',
      },
      cnh: {
        front: '@:steps.documentDetector.capture.title.cnh.front',
        back: '@:steps.documentDetector.capture.title.cnh.back',
      },
      error: '@:steps.documentDetector.capture.title.error',
      already: '@:steps.documentDetector.capture.title.already',
    },
    error: {
      front: '@:steps.documentDetector.capture.error.front',
      back: '@:steps.documentDetector.capture.error.back',
    },
    success: '¡Foto tomada con éxito!',
    loading: 'Enviamos la foto para ser analizada\nEspera un momento...',
    warning: {
      rg: 'RG validado en el primer intento',
      cnh: 'CNH validada en el primer intento',
    },
  },
  facematch: {
    error: 'Aún no te hemos identificado\nIntenta usar otro documento',
    loading: 'Comparando la selfie con la foto del documento...',
  },
  error: {
    changeDocument: '@:steps.documentDetector.capture.error.changeDocument',
    expiredDocument: '@:steps.documentDetector.capture.error.expiredDocument',
    invalidCpf: '@:steps.documentDetector.capture.error.invalidCpf',
  },
};

export default facialBiometrics;
