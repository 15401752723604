<script setup>
import { useRoute } from '@base/hooks';
import { computed, onMounted, ref } from 'vue';

defineEmits(['handle-new']);

const route = useRoute();

const name = ref('');

const nameParam = computed(() => route.query.name || '');

onMounted(() => {
  name.value = nameParam.value;
});
</script>

<template>
  <header :class="$style.header">
    <div :class="$style.contentTitle">
      <h1
        :class="$style.title"
        data-testid="pageTitle"
      >
        {{ $t('flowModule.title') }}

        <!-- TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED -->
        <!-- TODO: Remove the whole FeatureFlag block below -->
        <FeatureFlag name="toggleFlowSimplifiedJourneyEnabled">
          <XBadge
            color="brand-primary"
            icon="gem"
          >
            {{ $t('general.feature.beta') }}
          </XBadge>
        </FeatureFlag>
      </h1>
      <div :class="$style.actions">
        <XLink
          icon="question-circle"
          size="small"
          :to="$t('links.help.flows.withForm')"
          target="_blank"
          data-ga-event="link-help-flows-click"
        >
          {{ $t('base.help') }}
        </XLink>
        <XButton
          data-testid="createFlowButton"
          data-ga-event="button-create-flow-click"
          @click="$emit('handle-new')"
        >
          {{ $t('flowModule.new') }}
        </XButton>
      </div>
    </div>
  </header>
</template>

<style lang="scss" module>
.header {
  margin-bottom: var(--space-medium);

  .contentTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-regular);

    .title {
      margin: 0;
      gap: var(--space-small-xx);
      color: var(--color-brand-primary-600);
    }

    .actions {
      display: flex;
    }
  }
}
</style>
