import account from '@account/routes/index';
import auth from '@auth/routes/index';
import dashboard from '@dashboard/routes/index';
import document from '@document/routes/index';
import flow from '@flow/routes/index';
import operators from '@operators/routes/index';
import report from '@report/routes/index';
import tracking from '@tracking/routes/index';
import acceptanceTerm from '@acceptance-term/routes/index';
import signerArea from '@signer-area/routes/index';
import users from '@users/routes/index';
import envelope from '@envelope/routes/index';
import workspace from '@workspaces/routes/index';
import guest from '@guest/routes';
import errors from '../../errors/routes/index';

export const routes = [
  ...account,
  ...users,
  ...auth,
  ...dashboard,
  ...document,
  ...flow,
  ...operators,
  ...report,
  ...tracking,
  ...acceptanceTerm,
  ...signerArea,
  ...envelope,
  ...workspace,
  ...guest,
  ...errors,
];
