export default {
  getEnvelope: (state) => state,
  getLinks: (state) => state.links,
  getStatus: (state) => state.status,
  getAncestors: (state) => state.ancestors,
  getDocuments: (state) => [...state.uploadingDocuments, ...state.documents],
  getFolder: (state) => state.folder,
  getName: (state) => state.name,
  getSettings: (state) => state.settings,
  getSigners: (state) => state.signers,
  getGroups: (state) => state.groups,
  getSignatures: (state) => state.signatures,
  getCurrentGroup: (state) => state.currentGroup,
  hasWhatsappSigners: (state) =>
    state.signers.some((signer) => signer.communicateBy === 'whatsapp' && signer.signable),
  onlyWhatsappSigners: (state) =>
    state.signers
      .filter((signer) => !!signer.signable)
      .every((signer) => signer.communicateBy === 'whatsapp'),
  canRemind: (state) => state.signers.some((signer) => !!signer.signable),
  getUploadingDocuments: (state) => state.uploadingDocuments,
  getDeletingDocuments: (state) => state.deletingDocuments,
  getEditSession: (state) => state.editSession,
  getMaxArchives: (state) => state.maxArchives,
  getMaxDocumentContentSize: (state) => state.maxDocumentContentSize,
  getMaxImageContentSize: (state) => state.maxImageContentSize,
  getRemainingDocumentsCount: (state) => state.remainingDocumentsCount,
  hasEmailNotifiable: (state) => state.hasEmailNotifiable,
  hasWhatsappNotifiable: (state) => state.hasWhatsappNotifiable,
  hasOperations: (state) => state.hasOperations,
  getBlockAfterRefusal: (state) => state.blockAfterRefusal,
  isRefused: (state) => state.refused,
  getDefaultMessage: (state) => state.defaultMessage,
  getPendingRefusedSignaturesCount: (state) => {
    const pendingDocumentsRefusedCount = state.signatures?.reduce((count, group) => {
      const signersDocuments = group.signers.map((signer) => signer.documents).flat();

      return (
        count + signersDocuments?.filter((doc) => doc.refused && doc.status === 'pending').length
      );
    }, 0);
    return pendingDocumentsRefusedCount;
  },
  getAutoSign: (state) => state.editSession?.autoSign,
};
