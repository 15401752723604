export default {
  documents: [],
  name: '',
  status: 'running',
  deadlineAt: null,
  finishedAt: null,
  ancestors: [],
  folderName: '',
  folderPath: '',
  folderIsPrivate: false,
  folderIsTrashBin: false,
  links: {},
  signers: [],
  signatures: [],
  currentGroup: null,
  settings: {},
  editSession: null,
  folder: {},
  maxArchives: 0,
  maxDocumentContentSize: 0,
  maxImageContentSize: 0,
  uploadingDocuments: [],
  deletingDocuments: [],
  hasWhatsappNotifiable: false,
  hasEmailNotifiable: false,
  hasOperations: false,
  groups: {},
  blockAfterRefusal: false,
  refused: false,
  defaultMessage: '',
  autoSign: false,
};
