const documentDetector = {
  capture: {
    title: {
      rg: {
        front: 'Frente de tu RG',
        back: 'Reverso de tu RG',
      },
      cnh: {
        front: 'Frente de tu CNH',
        back: 'Reverso de tu CNH',
      },
      any: {
        front: 'Frente de tu documento',
        back: 'Verso de tu documento',
      },
      error: 'Hemos encontrado un problema',
      already: 'Foto ya validada',
    },

    error: {
      front: 'Frente del documento no reconocida\nInténtalo nuevamente',
      back: 'Reverso del documento no reconocido\nInténtalo nuevamente',
      expiredDocument: 'Documento vencido',
      invalidCpf: 'El CPF del documento no es el mismo que el informado para esta firma',
      changeDocument: 'Cambiar documento',
    },

    loading: 'Enviamos la foto para ser analizada\nEspera un momento...',
    success: '¡Foto tomada con éxito!',
  },
};

export default documentDetector;
