import { FEATURES } from './features';

export default {
  NAVIGATION: {
    documents: {
      name: 'documents',
      open: true,
      items: [
        {
          name: 'rootFolder',
          path: 'rootFolder',
          icon: 'copy',
          dataTestid: 'documentsLink',
        },
        {
          name: 'runningDocuments',
          path: 'runningDocuments',
          icon: 'clock',
          dataTestid: 'runningDocumentsLink',
        },
        {
          name: 'closedDocuments',
          path: 'closedDocuments',
          icon: 'check-circle',
          dataTestid: 'finishedDocumentsLink',
        },
        {
          name: 'canceledDocuments',
          path: 'canceledDocuments',
          icon: 'times-circle',
          dataTestid: 'canceledDocumentsLink',
        },
        {
          name: 'downloads',
          path: 'downloadsPacks',
          icon: 'download',
          iconStyle: 'fak',
          dataTestid: 'downloadsLink',
          notification: 'downloads',
        },
        {
          name: 'draftPacks',
          path: 'draftPacks',
          icon: 'file-edit',
          dataTestid: 'draftsListLink',
        },
        {
          name: 'trashBin',
          path: 'trashBin',
          icon: 'trash',
          dataTestid: 'trashDocumentsLink',
        },
      ],
    },
    acceptance: {
      name: 'acceptance',
      open: !!window.Cypress,
      hideToForeignUser: true,
      items: [
        {
          name: 'acceptanceTermWhatsappIndex',
          path: 'acceptanceTermWhatsappIndex',
          icon: 'whatsapp',
          iconStyle: 'fab',
          dataTestid: 'acceptWhatsappLink',
          functionality: FEATURES.WHATSAPP_ACCEPTANCE,
        },
        {
          name: 'acceptanceTermContentTemplates',
          path: 'acceptanceTermContentTemplates',
          icon: 'clipboard-list',
          dataTestid: 'acceptanceTermContentTemplateLink',
          functionality: FEATURES.WHATSAPP_ACCEPTANCE,
        },
      ],
    },
    management: {
      name: 'management',
      open: !!window.Cypress,
      items: [
        {
          name: 'reports',
          path: 'reports',
          icon: 'table',
          hideToForeignUser: true,
          dataTestid: 'reportsLink',
        },
        {
          name: 'dashboardDocumentDetails',
          path: 'dashboardDocumentDetails',
          icon: 'calendar-alt',
          dataTestid: 'dashboardDetailsLink',
        },
        {
          name: 'trackingNotifications',
          path: 'trackingNotifications',
          icon: 'mail-bulk',
          alert: 'emailBounces',
          dataTestid: 'trackingMenuOption',
        },
        {
          name: 'contacts',
          path: 'contacts',
          icon: 'address-book',
          dataTestid: 'contactsListLink',
        },
      ],
    },
    automation: {
      name: 'automation',
      open: !!window.Cypress,
      hideToForeignUser: true,
      items: [
        {
          name: 'newTemplate',
          icon: 'sparkles',
          dataTestid: 'newChatTemplate',
        },
        {
          name: 'templates',
          path: 'templates',
          icon: 'file-invoice',
          dataTestid: 'templatesLink',
        },
        // NEW_MENU_AUTOMATION
        // TODO: remove formBuilders
        {
          name: 'formBuilders',
          path: 'formBuilders',
          icon: 'clipboard',
          dataTestid: 'formsMenuOption',
        },
        {
          name: 'formFlows',
          icon: 'code-branch',
          // TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED
          // TODO: Remove betaDependsOnToggle property
          betaDependsOnToggle: 'toggleFlowSimplifiedJourneyEnabled',
          dataTestid: 'flowsMenuOption',
          subItems: [
            {
              name: 'pendingAnswers',
              path: 'pendingAnswers',
              // NEW_MENU_AUTOMATION
              // TODO: remove subItem key below
              subItem: true,
              dataTestid: 'pendingAnswersOption',
            },
            {
              name: 'flowProcesses',
              path: 'flowProcesses',
              // NEW_MENU_AUTOMATION
              // TODO: remove subItem key below
              subItem: true,
              dataTestid: 'flowsApprovalsMenuOption',
            },
          ],
        },
        // NEW_MENU_AUTOMATION
        // TODO: remove pendingAnswers
        {
          name: 'pendingAnswers',
          icon: 'hourglass',
          dataTestid: 'pendingAnswersOption',
        },
        {
          name: 'spreadsheetFlows',
          path: 'spreadsheetFlows',
          icon: 'table',
          dataTestid: 'spreadsheetFlowsMenuOption',
        },
        // NEW_MENU_AUTOMATION
        // TODO: remove flowProcesses
        {
          name: 'flowProcesses',
          icon: 'tasks',
          dataTestid: 'flowsApprovalsMenuOption',
        },
      ],
    },
    help: {
      name: 'help',
      open: !!window.Cypress,
      hideToForeignUser: true,
      items: [
        {
          name: 'faq',
          path: 'faq',
          icon: 'lightbulb',
          dataTestid: 'faqLink',
        },
        {
          name: 'support',
          path: 'support',
          icon: 'comment',
          dataTestid: 'supportLink',
        },
        {
          name: 'commercial',
          path: 'commercial',
          icon: 'phone',
          dataTestid: 'commercialLink',
        },
      ],
    },
  },
};
