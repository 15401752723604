import { useStore } from '@/store';
import { isEmpty } from 'lodash';

const filterUndefinedValues = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    if (data[key] !== undefined) {
      acc[key] = data[key];
    }
    return acc;
  }, {});
};

export const useEmitPageDataLayer = () => {
  const store = useStore();
  const accountData = store.getters['info/getAccount'];

  // remover envio duplicado do evento de pageView quando migração estiver completa
  const sendPageView = (path) => {
    const account = {
      current_account_customer_vindi_key: accountData.vindKey || '',
      current_account_id: String(accountData.id),
      current_account_name: accountData.name,
      current_account_segment: accountData.segment?.name,
      current_account_plan: accountData.plan,
      current_account_created_at: accountData.createdAt,
      current_account_: accountData.createdAt,
    };
    const user = {
      current_user_email: accountData?.user.email,
      current_user_id: String(accountData?.user.id),
      current_user_name: accountData.user.name,
      current_user_email_confirmed: accountData?.user.confirmed,
    };
    const pageViewData = {
      event: 'pageView',
      virtualUrl: path,
      ...(!isEmpty(accountData) && { ...account }),
      ...(!isEmpty(accountData?.user) && { ...user }),
    };

    window.dataLayer.push(pageViewData);
  };

  const initDataLayerPush = async (path) => {
    if (accountData?.user) sendPageView(path);

    const { id, name, createdAt, email, confirmed, location } = accountData?.user || {};

    const account = {
      account_id: accountData?.id,
      account_name: accountData?.name,
      account_created_at: accountData?.createdAt,
      segment: accountData?.segment?.name,
      reason: accountData?.reason,
      documents_amount: accountData?.documentsAmount,
      plan: accountData?.plan,
      plan_tier: accountData?.planTier,
      intended_plan: accountData?.intendedPlan,
      express_subscription: accountData?.expressSubscription,
      customer_vindi_key: accountData?.vindKey,
    };

    const user = {
      user_id: id,
      name,
      user_created_at: createdAt,
      email,
      email_confirmed: confirmed,
      location,
    };

    const initDataLayerData = {
      event: 'initDataLayer',
      virtualUrl: path,
      user: filterUndefinedValues(user),
      account: filterUndefinedValues(account),
    };

    window.dataLayer.push(initDataLayerData);
  };

  return {
    initDataLayerPush,
  };
};
