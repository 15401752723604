const documentDetector = {
  capture: {
    title: {
      rg: {
        front: 'Front of your RG',
        back: 'Back of your RG',
      },
      cnh: {
        front: 'Front of your CNH',
        back: 'Back of your CNH',
      },
      any: {
        front: 'Front of your document',
        back: 'Back of your document',
      },
      error: 'We found a problem',
      already: 'Photo already validated!',
    },

    error: {
      front: 'Front of unrecognized document\nTry again',
      back: 'Back of unrecognized document\nTry again',
      expiredDocument: 'Expired document',
      invalidCpf: 'The CPF on the document does not match the one provided for this signature',
      changeDocument: 'Change document',
    },

    success: 'Photo taken successfully!',
    loading: 'We send the photo for review\nWait a moment...',
  },
};

export default documentDetector;
