const reports = {
  index: {
    reports: 'Informes',
    banner: {
      moreForYou: 'Mucho más poder para ti',
      documentsAndFlow: 'administrar tus documentos y flujos',
    },
    list: {
      audit: 'Auditoría de la cuenta',
      documents: 'Documentos',
      signers: 'Firmantes',
      flows: 'Flujos',
      consumption: 'Consumo',
    },
  },
  actions: {
    download: {
      csv: 'Descargar CSV',
      xlsx: 'Descargar XLSX',
    },
    filter: 'Filtrar',
    generateReport: 'Generar informe',
    select: 'Seleccionar',
  },
  date: {
    start: 'Inicio',
    end: 'Final',
    created: 'Fecha de creación',
    finished: 'Fecha de finalización',
  },
  table: {
    rowsNumber: 'Número de filas',
    documentStatuses: {
      canceled: 'Cancelado',
      closed: 'Finalizado',
      running: 'En proceso',
    },
    pendingSignature: 'Suscripción pendiente',
  },
  loading: 'Espera un momento...',
  dataPlaceholder: 'Sin datos para visualizar',
  document: {
    title: 'Informe de Documentos',
    columns: {
      name: 'Nombre del documento',
      status: 'Estatus del documento',
      createdAt: 'Fecha de creación',
      deadlineAt: 'Fecha límite',
      finishedAt: 'Fecha de finalización',
      userEmail: 'Correo electrónico del operador',
      key: 'Clave del documento',
      signersCount: 'Nº de firmantes',
      signaturesCount: 'Nº de firmas',
      refused: 'Con firmas rechazadas',
    },
    responses: {
      yes: 'Sí',
      no: 'No',
    },
    modal: {
      title: 'Detalles del documento',
    },
  },
  signer: {
    title: 'Informe de Firmantes',
    generateReport: 'Generar informe',
    columns: {
      documentName: 'Nombre del documento',
      documentStatus: 'Estatus del documento',
      documentUserEmail: 'Correo electrónico del operador',
      documentKey: 'Clave del documento',
      signerEmail: 'Correo electrónico del firmante',
      signerPhoneNumber: 'Celular',
      signatureStatus: 'Estatus de la firma',
      signedAt: 'Fecha y hora de la firma',
      signerName: 'Nombre',
      signerSignAs: 'Firmar como',
      documentCreatedAt: 'Fecha de creación',
      documentDeadlineAt: 'Fecha límite',
      documentFinishedAt: 'Fecha de finalización',
      signerDocumentation: 'Documento de identidad',
      signerBirthday: 'Fecha de nacimiento',
      signerAuth: 'Autenticación vía',
      signatureValidation: 'Verificación fiscal federal',
      documentUrl: 'URL del documento',
      filterSign: 'Filtrar suscripciones',
    },
    dataPlaceholder: 'Sin datos para visualizar',
    modal: {
      title: 'Detalles del firmante',
    },
  },
  flow: {
    title: 'Informe de Flujos',
    limit: 'Número de filas',
    selectFlow: 'Selecciona un flujo',
    noFlows: 'Sin flujos añadidos.',
    createFlow: 'Crea un flujo.',
    columns: {
      documentName: 'Nombre del documento',
      documentStatus: 'Estatus del documento',
      formOneFilledAt: 'Completar del formulario 1',
      formTwoFilledAt: 'Completar del formulario 2',
      document_url: 'URL del documento',
    },
    tooltips: {
      noFlows: 'Mostrando solo los flujos para los que tienes permiso.',
    },
    modal: {
      title: 'Detalles del flujo',
      unanswered: 'No proporcionado',
    },
  },
  consumption: {
    title: 'Historial de Consumo',
    periodTypes: {
      fixed: {
        label: 'Por período de factura',
        tooltip: 'Fecha de inicio y final del consumo de la factura',
      },
      custom: 'Por fecha personalizada',
    },
    period: 'Período',
    limit: 'Número de filas',
    showingItems: 'Mostrando {0} de {1}',
    showAllItems: 'Mostrar todo',
    generateReport: 'Generar',
    downloadCsv: 'Descargar CSV',
    exportCsv: 'Exportar CSV',
    downloadXlsx: 'Descargar XLSX',
    content: {
      title: 'Mostrando resultados del período de facturación elegido',
    },
    data: {
      title: {
        custom: 'Mostrando el período por fecha personalizada',
        fixed: 'Mostrando el período en que se emitió la factura',
      },
      empty: {
        title: 'Sin datos de consumo para mostrar',
        text: 'Selecciona un período o fecha y haga click en generar',
        fixed: 'Selecciona un período y haga click en generar',
      },
    },
    columns: {
      productName: 'Producto',
      quantity: 'Cantidad',
      documentKey: 'Clave del documento',
      documentName: 'Nombre del documento',
      documentUserEmail: 'Correo electrónico del operador',
      documentFinishedAt: 'Fecha de finalización',
    },
    modal: {
      title: 'El archivo CSV será enviado a {userEmail}',
      content: 'Recibirás un correo electrónico de nosotros en breve.',
    },
    alert: {
      exportCSV: 'da click en Exportar CSV.',
      support: 'ponte en contacto con el equipo soporte.',
      message:
        'Mostrando 500 de los {0} resultados encontrados. Para visualizar todos los resultados,{1}',
    },
    usage: {
      helpLink: '¿Cómo funciona la facturación?',
      numberOfRows: '{rows} filas',
      headers: {
        details: {
          signerKey: 'Clave del firmante',
          signerEmail: 'Firmante',
          whatsappSignerPhoneNumber: 'Firmante',
          signerPhoneNumber: 'Número de teléfono del firmante',
          phoneNumber: 'Número de teléfono',
          createdAt: 'Fecha de creación',
          contentName: 'Nombre del documento',
          key: 'Clave del documento',
          finishedAt: 'Fecha de finalización',
          userEmail: 'Operador',
          sendAt: 'Fecha de envío',
          resendAttempts: 'Intentos de reenvío',
        },
      },
      plans: {
        integration: 'Plan integración',
        eventual: 'Plan eventual',
        essencial: 'Plan esencial',
        profissional: 'Plan profesional',
        empresarial: 'Plan empresarial',
        budget: 'Plan presupuesto',
        basic: 'Plan Básico',
        standard: 'Plan Estándar',
        ilimitado: 'Plan ilimitado',
        growth: 'Plan Crecimiento',
        nano: 'Plan Nano',
        fluxia: 'Plan Fluxia',
        custom: 'Plan Custom',
      },
      products: {
        sms: 'SMS',
        document: 'Documentos',
        whatsapp: 'WhatsApp',
        user: 'Usuarios adicionales',
        biometricsAuthenticationCafSerpro: 'Biometría facial SERPRO',
        facialBiometricsCaf: 'Biometría facial',
        facialBiometrics: 'Biometría facial',
        documentscopyAuthentication: 'Documentoscopia',
        biometricsAuthentication: 'Biometría SERPRO',
        acceptanceTermWhatsapp: 'Aceites via WhatsApp',
        widget: 'Widget Incrustado',
        dynamicSelfieAuthentication: 'Selfie dinámica',
        timestamp: 'Sello de tiempo',
      },
      period: {
        general: 'Mostrando informe de consumo de {startDate} a {endDate}',
        document: 'Documentos firmados (de {startDate} a {endDate})',
        whatsapp: 'WhatsApp enviado (de {startDate} a {endDate})',
        facialBiometricsCaf: 'Biometría facial (de {startDate} a {endDate})',
        facialBiometrics: 'Biometría facial (de {startDate} a {endDate})',
        biometricsAuthenticationCafSerpro: 'Biometría SERPRO (de {startDate} a {endDate})',
        biometricsAuthentication: 'Biometría SERPRO (de {startDate} a {endDate})',
        documentscopyAuthentication: 'Documentoscopia (de {startDate} a {endDate})',
        acceptanceTermWhatsapp: 'Aceptar vía WhatsApp (de {startDate} a {endDate})',
        dynamicSelfieAuthentication: 'Selfie dinámica (de {startDate} a {endDate})',
        timestamp: 'Sello de tiempo (de {startDate} a {endDate})',
      },
      button: {
        title: 'Descargar informe completo',
        download: 'Descargar CSV',
      },
      empty: {
        message: 'seleccione un período',
      },
    },
  },
};

export default reports;
