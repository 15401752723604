import { axios } from '@/plugins';

const getFlowProcessList = async ({ commit }, endpoint) => {
  const { data } = await axios.get(endpoint);

  commit('SET_FLOW_PROCESSES', data.flowProcesses);
  commit('SET_PAGINATION', data.pagination);
  commit('SET_LINKS', data.links);
};

const getAnswers = async ({ commit, getters }) => {
  const { data } = await axios.get(getters.getSelectedProcess.links.answers);

  commit('SET_PROCESS_DATA', data);
};

const approveProcess = async ({ getters }) => {
  const { data } = await axios.patch(getters.getSelectedProcess.links.approve);

  return data;
};

const disapproveProcess = async ({ getters }) => {
  const { data } = await axios.patch(getters.getLinks.disapprove, {
    ids: [getters.getSelectedProcess.id],
  });

  return data;
};

const fetchFlowsFilter = async ({ commit, getters }) => {
  const {
    data: {
      filters: { flows },
    },
  } = await axios.get(getters.getLinks.filterForms);

  commit('SET_FLOWS_FILTER', flows);
};

export default {
  getFlowProcessList,
  getAnswers,
  approveProcess,
  disapproveProcess,
  fetchFlowsFilter,
};
