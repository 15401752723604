import document from './document';
import envelope from './envelope';

const guest = {
  document,
  envelope,
  signinOrSignup: 'Iniciar sesión',
  seeSignatures: 'Ver firmas',
  errors: {
    expired: 'No tienes acceso a este documento, contacta al remitente',
    accessDenied: 'No tienes acceso al documento firmado, contacta al remitente',
  },
};

export default guest;
