const pendingAnswers = {
  actionReminder: 'Lembrete reenviado com sucesso! | Lembretes reenviados com sucesso!',
  actionDisapprove: 'Resposta excluída com sucesso! | Respostas excluídas com sucesso!',
  actionDisapproveOld: 'Resposta excluída com sucesso!',
  actionError: 'Ocorreu um erro ao realizar a ação!',
  emptyText:
    'Não encontramos nenhuma resposta pendente do 2º formulário. {0} que tal selecionar um fluxo diferente?',
  header: {
    title: 'Respostas pendentes do 2º formulário',
    alert: 'Confira quem falta preencher e reenvie o lembrete do formulário, se desejar',
    filter: {
      label: 'Fluxos',
      placeholder: 'Selecione o fluxo',
      clear: 'Limpar',
      button: 'Filtrar',
    },
  },
  filters: {
    apply: 'Aplicar',
    clear: 'Limpar filtros',
    dateRangePlaceholder: 'Tudo',
    flows: 'Fluxos',
    search: 'Busque por e-mail, telefone ou nome do fluxo',
  },
  table: {
    secondRecipient: 'Destinatário',
    name: 'Nome do fluxo',
    createdAt: 'Preenchido em',
    action: 'Ações',
    reminder: 'Reenviar lembrete',
    delete: 'Excluir resposta',
  },
  detailsModal: {
    title: 'Detalhes da pendência',
    flowDetails: 'Detalhes do fluxo',
    flowAnswers: 'Respostas do 1º formulário',
    firstFlowName: 'Nome do 1º formulário',
    secondFlowName: 'Nome do 2º formulário',
    identifier: 'Identificador',
  },
  disapproveModal: {
    title: 'Excluir resposta pendente do fluxo?',
    description: 'O link do 2º formulário ficará indisponível para esta pessoa.',
  },
  whatsappReminderModal: {
    title: 'Reenviar link via WhatsApp',
    description: 'O signatário receberá o link do segundo formulário novamente.',
    alert: 'Para envios via WhatsApp, há um custo de R$ 0,40 por lembrete.',
  },
  bulkActions: {
    totalSelected: '{count} item selecionado | {count} itens selecionados',
    reminder: 'Reenviar lembrete',
    delete: 'Excluir',
    disapproveModal: {
      title: 'Excluir respostas pendentes?',
      description:
        'Ao excluir as respostas pendentes selecionadas os links do 2° formulário ficarão indisponíveis para os destinários.',
    },
    reminderModal: {
      title: 'Reenviar lembretes?',
      description:
        'Deseja reenviar o lembrete de resposta pendente para os destinatários selecionados?',
    },
    whatsappReminderModal: {
      title: 'Reenviar lembretes via WhatsApp?',
      description:
        'Os destinatários selecionados irão receber o link do segundo formulário novamente.',
      alert: 'Para envios via WhatsApp, há um custo de R$ 0,40 por lembrete.',
    },
  },
};

export default pendingAnswers;
