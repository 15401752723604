const signatures = {
  refusal: {
    seeReason: 'Ver motivo del rechazo',
    removeFromEnvelope: 'Eliminar el sobre',
    removeFromDocument: 'Eliminar el documento',
    canRemoveSignerFromEnvelope:
      'Puedes eliminar al firmante de este sobre y agregarlo de nuevo para que reciba una nueva solicitud de firma.',
    canRemoveSignerFromDocument:
      'Puedes eliminar al firmante de este documento y volver a agregarlo para que reciba una nueva solicitud de firma.',
  },
  remind: {
    signerWillReceiveLinkAgain:
      'El firmante recibirá nuevamente el enlace para firmar los documentos pendientes.',
  },
  empty: {
    title: 'Ningún firmante agregado',
    description: 'Edita el sobre para agregado quién firmará',
    action: 'Editar sobre',
  },
  actions: {
    remindAll: 'Enviar recordatorios',
    editSigners: 'Editar firmantes',
  },
  autoSign: {
    sequencedTooltip:
      'Ao marcar esta opção, quando chegar a sua vez de assinar, todos os documentos serão assinados automaticamente com seus dados cadastrados.',
    tooltip:
      'Ao marcar esta opção, todos os documentos serão assinados automaticamente com seus dados cadastrados.',
  },
};

export default signatures;
