<script setup>
import { computed, onMounted, ref } from 'vue';

import { useFunctionalities, useListenForGaEvents, useRoute, useToast } from '@base/hooks';
import { FEATURES } from '@base/constants';
import { useStore } from '@/store';
import { useI18n } from '@/locales';

import FlowDisabledContainer from '@flow/containers/disabled/FlowDisabledContainer.vue';
import { FormFlowListHeader } from '@flow/components/form-flow';
import { useFormFlowListActions } from '@flow/composables/useFormFlowListActions';

const FORM_FLOW_LIST_FLOWS = 'formFlowListFlows';

const { isEnabledAndNotBlockedByAdmin } = useFunctionalities();
const { handleNew } = useFormFlowListActions();
const store = useStore();
const route = useRoute();
const { t } = useI18n();
const toast = useToast();

const root = ref();

useListenForGaEvents(root, 'automation-form-flows-app');

const tabItems = [
  { title: t('flowModule.list.tab.flow'), name: 'flow', to: { name: FORM_FLOW_LIST_FLOWS } },
  {
    title: t('flowModule.list.tab.folders'),
    name: 'folder',
    to: { name: 'formFlowListFolders' },
  },
];

const isFormFlowEnabled = computed(() => isEnabledAndNotBlockedByAdmin(FEATURES.FORM_FLOW));

const activeTabName = computed(() => (route.name === 'formFlowListFolders' ? 'folder' : 'flow'));

const fetchFormFlows = async () => {
  try {
    store.commit('formFlow/SET_LINKS', { self: window.location.href });
    await store.dispatch('formFlow/fetchFormFlows');
  } catch (err) {
    toast.error(t('flowModule.list.error'));
  }
};

onMounted(() => {
  if (route.name === FORM_FLOW_LIST_FLOWS) {
    fetchFormFlows();
  }
});
</script>

<template>
  <main>
    <template v-if="isFormFlowEnabled">
      <section
        ref="root"
        :class="$style.wrapper"
      >
        <FormFlowListHeader
          data-testid="formFlowListHeader"
          @handle-new="handleNew"
        />
        <XTabs
          :items="tabItems"
          :active-tab="activeTabName"
          :class="$style.tabs"
        />
        <XTabsPanel
          :class="$style.tabsPanel"
          :tab-name="activeTabName"
          is-always-visible
        >
          <router-view />
        </XTabsPanel>
      </section>
    </template>
    <FlowDisabledContainer
      v-else
      type="default"
      data-testid="flowDisabledContainer"
    />
  </main>
</template>

<style lang="scss" module>
.tabs {
  width: 100%;
  margin-bottom: var(--space-regular);
}

.tabsPanel {
  display: block;
}

.wrapper {
  margin: var(--space-large);
}
</style>
