<script setup>
import {
  FormFlowTableList,
  FormFlowListActions,
  FormFlowCardList,
} from '@flow/components/form-flow';
import { useFormFlowListActions } from '@flow/composables/useFormFlowListActions';
import { useFormFlowViewToggle } from '@flow/composables';
import { FLOW_CONTEXT_NAMES_CONSTANTS, VIEW_OPTIONS_CONSTANTS } from '@flow/constants';
import { useToggle } from '@base/hooks';
import { computed } from 'vue';

defineProps({
  context: {
    type: String,
    default: FLOW_CONTEXT_NAMES_CONSTANTS.flowList,
  },
});

const {
  tableEl,
  selectedRows,
  isLoading,
  resetSelectedRows,
  modalShowed,
  handleCloseModal,
  handleDelete,
  handleDisable,
  handleBulkDisable,
  handleBulkDelete,
  handleBulkDuplicate,
  handleMove,
  handleNewFolder,
  handleCreateFolder,
  handleRemoveFromFolder,
  handleNew,
  handleOpenModal,
  handleClickOption,
} = useFormFlowListActions();
const { currentView, viewOptions } = useFormFlowViewToggle();
// TOGGLE_CHANGE_FLOW_LISTING_VIEW
// TODO: Remove toggle declaration
const { isEnabled: toggleChangeFlowListingViewEnabled } = useToggle('change_flow_listing_view');

const showCardList = computed(() => currentView.value === VIEW_OPTIONS_CONSTANTS.grid);
</script>

<template>
  <div>
    <!-- TOGGLE_CHANGE_FLOW_LISTING_VIEW -->
    <!-- TODO: Remove toggleChangeFlowListingViewEnabled from if condition -->
    <FormFlowCardList
      v-if="toggleChangeFlowListingViewEnabled && showCardList"
      :context="context"
      :current-view.sync="currentView"
      :view-options="viewOptions"
      @handle-new="handleNew"
      @open-modal="handleOpenModal"
      @click-option="handleClickOption"
    />
    <!-- TOGGLE_CHANGE_FLOW_LISTING_VIEW -->
    <!-- TODO: Remove :toggle-change-flow-listing-view-enabled prop -->
    <FormFlowTableList
      v-else
      :table-el.sync="tableEl"
      :selected-rows.sync="selectedRows"
      :context="context"
      :current-view.sync="currentView"
      :view-options="viewOptions"
      :toggle-change-flow-listing-view-enabled="toggleChangeFlowListingViewEnabled"
      @reset-selected-rows="resetSelectedRows"
      @handle-new="handleNew"
      @open-modal="handleOpenModal"
      @click-option="handleClickOption"
    />
    <FormFlowListActions
      :modal-showed="modalShowed"
      :is-loading="isLoading"
      :selected-rows="selectedRows"
      data-testid="formFlowListActions"
      @close="handleCloseModal"
      @disable="handleDisable"
      @delete="handleDelete"
      @bulk-disable="handleBulkDisable"
      @bulk-delete="handleBulkDelete"
      @bulk-duplicate="handleBulkDuplicate"
      @handle-move="($e) => handleMove($e)"
      @new-folder="handleNewFolder"
      @create-folder="($e) => handleCreateFolder($e)"
      @remove-from-folder="() => handleRemoveFromFolder(selectedRows)"
    />
  </div>
</template>
