import { getViewProps } from '@base/utils/getViewDataset';
import { blockForeignUserGuard } from '@operators/routes/navigation-guards/block-foreign-users/index';

const routes = [
  {
    beforeEnter: blockForeignUserGuard,
    path: '/signer',
    component: () => import('@signer-area/layouts/SignerAreaLayout.vue'),
    redirect: '/signer/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'SignerAreaDashboard',
        component: () => import('@signer-area/views/SignerAreaDashboardView.vue'),
        props: getViewProps,
        meta: {
          refresh: () => true,
          layout: 'BaseCleanLayout',
        },
      },
      {
        path: 'profile',
        component: () => import('@users/views/UserProfileView.vue'),
        props: getViewProps,
        meta: {
          refresh: () => true,
          layout: 'BaseCleanLayout',
        },
      },
      // TOGGLE_NEW_PREVIEW_ROUTE_ENABLED
      // TODO: Remove preview base route and children
      {
        path: 'preview',
        component: () => import('@signer-area/views/SignerAreaEnvelopePreviewerView.vue'),
        children: [
          {
            path: 'envelopes/:envelopeKey',
            name: 'SignerAreaEnvelopeDetailsOld',
            component: () =>
              import(
                '@signer-area/containers/SignerEnvelopeDetailsContainer/SignerEnvelopeDetailsContainer.vue'
              ),
          },
          {
            path: 'documents/:documentKey',
            name: 'SignerAreaDocumentDetailsOld',
            component: () =>
              import(
                '@signer-area/containers/SignerDocumentDetailsContainer/SignerDocumentDetailsContainer.vue'
              ),
          },
        ],
      },
      {
        path: ':signerKey([a-f0-9\\-]+)',
        component: () => import('@signer-area/views/SignerAreaOverviewView.vue'),
        children: [
          {
            path: 'envelopes/:envelopeKey',
            name: 'SignerAreaEnvelopeDetails',
            components: {
              details: () =>
                import(
                  '@signer-area/containers/OverviewEnvelopeContainer/OverviewEnvelopeContainer.vue'
                ),
              mainContent: () =>
                import(
                  '@signer-area/containers/SignerEnvelopeViewerContainer/SignerEnvelopeViewerContainer.vue'
                ),
            },
          },
          {
            path: 'documents/:documentKey',
            name: 'SignerAreaDocumentDetails',
            components: {
              details: () =>
                import(
                  '@signer-area/containers/OverviewDocumentContainer/OverviewDocumentContainer.vue'
                ),
              mainContent: () =>
                import(
                  '@signer-area/containers/SignerDocumentViewerContainer/SignerDocumentViewerContainer.vue'
                ),
            },
          },
        ],
      },
    ],
  },
];

export default routes;
