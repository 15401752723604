const facialBiometrics = {
  title: 'Biometria facial',
  documentType: {
    title: 'Qual documento você quer usar?',
    subtitle: 'Escolha aquele com a foto mais recente',
    iAgreeTo: 'Autorizo a @:steps.facialBiometrics.title',
    action: 'Tirar foto',
    officialDocuments: {
      cnh: {
        title: 'CNH',
        text: 'Habilitação',
      },
      rg: {
        title: 'RG',
        text: 'Identidade',
      },
    },
  },
  carousel: {
    step1: {
      title: '@:steps.facialBiometrics.title',
      text: 'Vamos tirar uma foto sua e do seu documento oficial para confirmar que você é você',
    },
    step2: {
      title: '@:steps.liveness.carousel.step2.title',
      text: {
        first: '@:steps.liveness.carousel.step2.text.first',
        second: '@:steps.liveness.carousel.step2.text.second',
        third: '@:steps.liveness.carousel.step2.text.third',
        fourth: '@:steps.liveness.carousel.step2.text.fourth',
      },
    },
    step3: {
      title: 'Ative sua câmera',
      text: 'Quando solicitado, toque em permitir para iniciar a captura da imagem',
    },
  },
  helpModal: {
    title: '@:steps.facialBiometrics.title',
    whatIs: {
      title: 'O que é?',
      description:
        'É uma forma de validar a identidade de quem assina ao comparar duas imagens: a foto selfie e a foto do documento.',
    },
    why: {
      title: 'Porque assinar com @:steps.facialBiometrics.title?',
      description:
        'Essa autenticação reduz a chance de fraudes, tornando a assinatura eletrônica ainda mais segura.',
    },
    learnMore: {
      base: 'Quer saber mais? Confira nossa {0} e {1}',
      privacyPolicy: 'Política de Privacidade',
      helpCenter: 'Central de Ajuda.',
    },
    authMethod: '*O remetente do documento escolheu esta forma de autenticação',
  },
  cameraError: {
    unsupportedBrowser: 'Navegador não suportado.',
    cameraMissing: 'Câmera não localizada.',
    cameraBusy: 'Câmera em uso por outro app.',
    cameraBlocked: 'Permissão negada para a câmera.',
    unknownCameraError: 'Ocorreu um erro ao iniciar a câmera.',
  },
  capture: {
    title: {
      rg: {
        front: '@:steps.documentDetector.capture.title.rg.front',
        back: '@:steps.documentDetector.capture.title.rg.back',
      },
      cnh: {
        front: '@:steps.documentDetector.capture.title.cnh.front',
        back: '@:steps.documentDetector.capture.title.cnh.back',
      },
      error: '@:steps.documentDetector.capture.title.error',
      already: '@:steps.documentDetector.capture.title.already',
    },
    error: {
      front: '@:steps.documentDetector.capture.error.front',
      back: '@:steps.documentDetector.capture.error.back',
    },
    success: 'Foto tirada com sucesso!',
    loading: 'Enviamos a foto para análise\nAguarde um momento...',
    warning: {
      rg: 'RG validado na primeira tentativa',
      cnh: 'CNH validada na primeira tentativa',
    },
  },
  facematch: {
    error: 'Ainda não identificamos você\nTente usar outro documento',
    loading: 'Comparando a selfie com a foto do documento...',
  },
  error: {
    changeDocument: '@:steps.documentDetector.capture.error.changeDocument',
    expiredDocument: '@:steps.documentDetector.capture.error.expiredDocument',
    invalidCpf: '@:steps.documentDetector.capture.error.invalidCpf',
  },
};

export default facialBiometrics;
