const membership = {
  title: 'Usuários',
  list: {
    title: 'Todos os usuários',
    member: {
      edit: 'Editar',
      remove: 'Remover da conta',
      consumptionAccess: 'Acesso à Cobrança',
      roles: {
        admin: 'Administrador',
        global: 'Membro',
        member: 'Membro',
        guest: 'Somente leitura',
        downloader: 'Observador com download',
        observer: 'Observador sem download',
      },
      invite: {
        pending: 'Ainda não entrou.',
      },
      apiUserWarning: 'Não pode ser removido. Membro configurado com API.',
      hasConsumptionAccess: 'Acesso à cobrança',
      hasTrackingAccess: 'Acesso à e-mails enviados',
      hasFolderManagementAccess: 'Acesso à gerenciamento de pastas',
    },
    pagination: {
      previous: 'Anterior',
      next: 'Próxima',
    },
    pendingInvites: {
      title: 'Convites pendentes',
    },
  },
  invite: {
    title: 'Adicionar usuário',
    form: {
      name: 'Nome (opcional)',
      email: 'E-mail',
      role: {
        label: 'Tipo de usuário',
        options: {
          admin: 'Administrador',
          global: 'Membro',
          observer: 'Observador (Não pode baixar documentos)',
          downloader: 'Observador (Pode baixar documentos)',
        },
      },
      consumption: 'Ver plano e cobrança da conta',
      tracking: 'Acessar e-mails enviados ',
      folderManagement: 'Gerenciar pastas',
      additionalAccessTitle: 'Permissões adicionais',
      moreInfo: 'Saiba mais.',
      group: 'Grupo (opcional)',
      groupHelpTooltip: 'Conceda permissões de acesso à pasta para grupos',
    },
    action: 'Adicionar',
    hasSamlInfo: 'Para adicionar usuários à conta, contate o Suporte.',
    notification: '{name} foi convidado com sucesso.',
  },
  edit: {
    title: 'Editar usuário',
    form: {
      consumptionAccess: 'Habilitar acesso à página de cobrança para este usuário. {0}',
      trackingAccess: 'Habilitar acesso à página de e-mails enviados para este usuário. {0}',
      moreInfo: 'Saiba mais.',
    },
    memberHasFlows: {
      title:
        'O usuário {name} possui seu e-mail configurado no seguinte Fluxo: | O usuário {name} possui seu e-mail configurado nos seguintes Fluxos:',
      description: 'Para mudar o tipo selecione um novo usuário para estes Fluxos.',
      info: 'Usuários tipo Observador não serão listados.',
    },
    roles: {
      title: 'Tipo de usuário',
      options: {
        admin: 'Administrador',
        global: 'Membro',
        observer: 'Observador (Não pode baixar documentos)',
        downloader: 'Observador (Pode baixar documentos)',
      },
    },
    notification: '{name} atualizado com sucesso',
  },
  delete: {
    title: 'Remover usuário da conta',
    description: 'Deseja mesmo remover {name} desta conta?',
    memberHasFlows: {
      title:
        'O usuário {name} possui seu e-mail configurado no seguinte Fluxo: | O usuário {name} possui seu e-mail configurado nos seguintes Fluxos:',
      description: 'Para remover este selecione um novo usuário para estes Fluxos.',
      info: 'Usuários tipo Observador não serão listados.',
      select: 'Selecionar',
      ownedFormFlows: 'Fluxos com Formulário',
      ownedSpreadsheetFlows: 'Fluxos com Planilha',
    },
    notification: '{name} foi removido com sucesso',
  },
  user: 'Usuário',
  memberActionsDropdown: {
    edit: 'Editar usuário',
    removeGroupUser: 'Remover deste grupo',
    removeAccountMember: 'Remover da conta',
  },
  help: {
    userTypesAndPermissions: 'Como escolher tipo de usuário e permissões',
    additionalPermissions: 'Entenda as permissões',
  },
};

export default membership;
