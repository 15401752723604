const dashboard = {
  lastUpdate: 'Última atualização em {date} às {time}.',
  reload: 'Recarregar',
  export: 'Exportar',
  unconfirmedEmail:
    'Confirme seu e-mail para enviar documentos para assinatura. Caso ainda não tenha recebido o e-mail de confirmação.',
  unconfirmedEmailLink: 'reenvie aqui.',
  privacy: 'Política de privacidade',
  terms: 'Termos de uso',
  trialSubscriptionAlert: {
    purchase: 'Escolha o melhor plano para você e contrate agora!',
    expired: 'Seu teste expirou',
    subscribe: 'Consultar planos',
  },
  widget: {
    sendDocument: {
      title: 'Nova assinatura',
      send: 'Adicionar documentos',
      allowedType: 'Formatos aceitos: .pdf e .docx',
      download: 'Baixar modelo',
      downloadInfo: 'Baixe um documento exemplo e adicione para testar um envio',
    },
    notification: {
      title: 'Notificações',
      newMessage: '1 nova notificação | {count} novas notificações',
      noNotifications: 'Sem novidades por aqui hoje.',
    },
    documentsStatuses: {
      title: 'Documentos',
      link: {
        viewReports: 'Ver relatório',
        allDocuments: 'Ver todos documentos',
      },
      filter: {
        thisMoment: 'Neste momento',
        last7Days: 'Últimos 7 dias',
        last15Days: 'Últimos 15 dias',
        last30Days: 'Últimos 30 dias',
      },
      status: {
        running: 'Em processo',
        closed: 'Finalizados',
        canceled: 'Cancelados',
        refused: 'Assinatura recusada | Assinaturas recusadas',
      },
      emptyState: {
        title: 'Você não enviou um documento para assinatura nos últimos 30 dias',
        text: 'O que acha de fazer um envio?',
        action: 'Veja como',
      },
    },
    help: {
      title: 'Precisa de ajuda?',
      helpCentral: 'Perguntas frequentes',
      support: 'Contato com o suporte',
      sales: 'Contato com o comercial',
    },
    signaturesOverview: {
      nearToEndDate: 'Próximos à data limite',
      pendentSignature: 'Com uma assinatura pendente',
      signatureTimeAverage: 'Tempo médio de assinatura',
      signaturePeriodicity: 'dia',
    },
    accountUsage: {
      title: 'Consumo do plano',
      plan: 'Plano:',
      changePlan: 'Alterar meu plano',
      consumptionPage: 'Ver página de consumo',
      currentPeriod: 'Período:',
      periodRange: '{initialDate} a {endDate}',
      remainingDocuments: 'Restantes',
      finishedDocuments: 'Assinados',
      canceledDocuments: 'Cancelados',
      whatsapp: 'WhatsApp',
      sms: 'SMS',
      finishedDocumentsInfo:
        'Documentos assinados até 3 dias antes do fim do período entram no consumo da conta',
      whatsappInfo: 'Disponível apenas para os planos Integração e Automação',
      periodicityMonthly: 'Documentos/mês',
      documents: 'Documentos',
      extras: 'Recursos extras',
      tokenBy: 'Envio de token por',
      graphLabels: {
        sent: 'Enviados',
        finished: 'Finalizados',
        canceled: 'Cancelados',
      },
    },
  },
  signatureActions: {
    sendDocuments: {
      title: 'Enviar documentos',
      action: 'Adicionar documento',
      createAutomation: 'Criar automação',
      sign: 'Assinar',
      secondaryAction: 'Automatizar documentos',
    },
    signDocuments: {
      title: 'Assinar documentos',
      action: 'Acessar área de assinatura',
      readonlySubtitle: 'Confira e assine seus documentos aqui',
      readonlyHelpLink: 'Entenda sobre a area de assinatura',
    },
  },
  integrationCta: {
    acquisition: {
      badge: 'Integração',
      title: 'Integre com a Clicksign',
      description:
        'Ganhe tempo enviando documentos do seu sistema {0} Conheça e teste gratuitamente!',
      descriptionHighlight: 'direto para a Clicksign.',
      action: 'Quero conhecer',
    },
    activate: {
      badge: 'Primeiros passos',
      title: 'Integração com a Clicksign',
      description: 'Documentos do {0}',
      descriptionHighlight: 'seu sistema direto para a Clicksign.',
      action: 'Configurar',
      dismiss: 'Dispensar',
    },
    upgrade: {
      badge: 'Upgrade',
      title: 'Integre com a Clicksign',
      description:
        'Ganhe tempo enviando documentos do seu sistema {0} Conheça e teste gratuitamente!',
      descriptionHighlight: 'direto para a Clicksign.',
      action: 'Quero conhecer',
    },
    activateModal: {
      header: 'Integração com a API Clicksign',
      description: 'Configure e teste a integração entre seu sistema e a Clicksign',
      info: {
        sandbox: {
          title: 'Ambiente sandbox',
          description: 'Teste a conexão com a API de forma gratuita e ilimitada',
        },
        config: {
          title: 'Configurações da API',
          description: 'Crie o Account token da conta e defina o usuário da API',
        },
        documentation: {
          title: 'Documentação da API',
          description: 'Conheça e comece a fazer requisições',
        },
      },
      actions: {
        title: 'Esse conteúdo foi útil?',
        yes: 'Sim',
        no: 'Não',
      },
    },
    upgradeModal: {
      header: 'Integração com a API Clicksign',
      title: 'Mais eficiência em seus processos',
      description:
        'Integrando com a API da Clicksign você automatiza tarefas, ganha tempo e reduz os erros.',
      info: {
        whatDoINeed: {
          title: 'O que eu preciso para integrar?',
          description:
            'Você precisa gerar seus documentos a partir de um sistema. Se é o seu caso, sua equipe de desenvolvedores pode começar a testar a integração agora mesmo!',
        },
        howDoITest: {
          title: 'Como eu começo a testar a integração?',
          description1: 'Temos um ambiente exclusivo para testes com a API, que é o {0}.',
          sandbox: 'Sandbox',
          description2: 'Os primeiros passos para integrar você encontra na {0}',
          documentation: 'documentação da API.',
        },
        howMuch: {
          title: 'Quanto custa um plano com integração com API?',
          description:
            'A partir de R$39 por mês. E o melhor: quanto maior a franquia contratada, menor o preço por documento.',
        },
        howDoIGet: {
          title: 'Como contratar?',
          description: 'Basta contratar um plano Start, Plus ou Avançado. {0}',
          link: 'Veja todas as opções aqui.',
        },
      },
      actions: {
        title: 'Esse conteúdo foi útil?',
        yes: 'Sim',
        no: 'Não',
      },
    },
    dismissActivateCardModal: {
      title: 'Deseja dispensar o conteúdo permanentemente?',
      description: 'As informações seguirão disponíveis em nossa central de ajuda.',
      actions: {
        cancel: 'Cancelar',
        confirm: 'Dispensar',
      },
      error: 'Erro ao dispensar conteúdo de ajuda',
    },
  },
  trialConversionCard: {
    badge: 'Contrate um plano',
    title: 'Ganhe agilidade e segurança com a assinatura eletrônica',
    firstText: 'Simplifique seus processos.',
    secondText: 'Planos a partir de R$ 39 no mês.',
    action: 'Quero contratar',
  },
  trustWindowModal: {
    forcedChurn: {
      title: 'Conta temporariamente reativada',
      description:
        'Sua fatura já foi emitida. Se o pagamento não for identificado até a data de vencimento, a conta será bloqueada novamente.',
      action: 'Ver fatura',
    },
    overduePayments: {
      title: 'Pagamento em análise',
      description: 'Enquanto a gente confere, você pode seguir usando a plataforma.',
      action: 'Entendi',
    },
    lastDay: {
      title: 'Pagamento ainda não identificado',
      description:
        'Sua conta será bloqueada novamente em 24 horas. Realize o pagamento ou envie o comprovante para garantir o seu acesso.',
      actions: {
        seeInvoice: 'Ver fatura',
        paymentVoucher: 'Enviar comprovante',
      },
    },
  },
};

export default dashboard;
