export const buildMembershipUpdateRequest = (payload) => {
  const membershipType = payload.membership.invite ? 'invites' : 'memberships';
  const membershipTypeKey = payload.membership.invite ? 'invite' : 'membership';
  const params = {
    accountId: payload.accountId,
    membershipId: payload.membership.id,
    membershipType,
  };
  const bodyData = {};
  Object.assign(
    bodyData,
    payload.migrateToMembershipId && { migrate_to_membership_id: payload.migrateToMembershipId },
    payload.membership.role && {
      [membershipTypeKey]: {
        role: payload.membership.role,
        consumption_accessible: payload.membership.consumptionAccessible,
        tracking_accessible: payload.membership.trackingAccessible,
        folder_management_accessible: payload.membership.folderManagementAccessible,
        group_ids: payload.membership.group_ids,
      },
    }
  );

  return { params, bodyData };
};

export const buildMembershipDeleteRequest = (payload) => {
  const membershipType = payload.membership.invite ? 'invites' : 'memberships';
  const params = {
    accountId: payload.accountId,
    membershipId: payload.membership.id,
    membershipType,
  };
  const bodyData = {};
  Object.assign(
    bodyData,
    payload.migrateToMembershipId && {
      migrate_to_membership_id: payload.migrateToMembershipId,
    }
  );

  return { params, bodyData };
};
