const formBuilder = {
  title: 'Formulários',
  new: 'Criar formulário',
  key: 'Chave',
  list: {
    title: 'Fluxo:',
    loading: 'Carregando lista de formulários...',
    empty: 'Crie formulários para gerar documentos com conteúdo personalizado',
    emptyFlow: 'Vá até {0} e selecione este formulário para criar um fluxo automatizado.',
    flowForm: 'Fluxos',
    spreadsheet: 'Fluxo com planilha',
    form: 'Fluxo com formulário',
    actions: {
      edit: 'Editar',
      options: 'Opções',
    },
  },
  feedback: {
    deleteSuccess: 'Formulário excluído com sucesso!',
    duplicateSuccess: 'Formulário duplicado com sucesso!',
    actionError: 'Não foi possível concluir esta ação. Tente novamente',
  },
  setup: {
    name: {
      title: 'Criação do formulário',
      label: 'Nome do formulário',
      placeholder: 'Digite um nome para você identificar este formulário',
      helpLinkText: 'Ajuda',
    },
    info: {
      addField:
        'Arraste e solte aqui os campos ou clique nos campos para completar o seu formulário',
      signerField:
        "Utilize a opção 'E-mail do signatário' quando você não souber o e-mail de quem irá assinar. Desta forma, assim que o formulário for preenchido, o signatário receberá o documento para assinar.",
      disclaimerSignerFields: {
        title: 'E-mail e WhatsApp do signatário',
        infoText:
          'Você pode usar esses campos para vincular o signatário na hora de criar um fluxo.',
        helpLinkText: 'Saiba mais sobre e-mail e Whatsapp do signatário',
      },
    },
    validation: {
      fieldAlreadyAdded: 'Não é possível adicionar este campo novamente.',
    },
    field: {
      title: 'Título',
      type: 'Tipo',
      required: 'Obrigatório',
      maxLength: 'Máximo de caracteres',
      numberRows: 'Número de linhas',
      label: {
        header: 'Cabeçalho',
        paragraph: 'Parágrafo',
        shortText: 'Texto curto',
        date: 'Data',
        longText: 'Texto longo',
        singleChoice: 'Única resposta',
        multipleChoices: 'Múltiplas respostas',
        dropdownList: 'Lista suspensa',
        phone: 'Telefone do signatário',
        email: 'E-mail do signatário',
        cpf: 'CPF',
        cnpj: 'CNPJ',
        attachment: 'Anexo',
        cep: 'CEP',
        currency: 'Monetário',
        whatsapp: 'Whatsapp do signatário',
        image: 'Imagem',
      },
      value: {
        option: 'Opção no formulário {number}',
        text: 'Texto para documento {number}',
      },
    },
    other: {
      text: 'Outro',
      enable: 'Habilitar &quot;Outro&quot;',
      permit: 'Permita a adição de uma opção não listada',
    },
    help: {
      text: 'Texto de ajuda',
      tooltip: 'Balão de ajuda',
      hint: 'Dica: Use no título o mesmo termo da variável do seu modelo.<br>Ex: Variável do modelo: {{Nome do aluno}} - Título do campo: Nome do aluno',
      imageHint:
        'Dica: Use no título o mesmo termo da variável do seu modelo. <br>Ex.: Variável do modelo: {{[imagem] Comprovante}} - Título do campo: Comprovante',
      placeholder: 'Texto de ajuda dentro do campo',
      description: 'Pode ser visualizado ao clicar no ícone "?"',
    },
    remove: {
      question: 'Remover campo?',
      warning: 'Ao removê-lo todos os campos vinculados à ele também serão removidos.',
    },
    option: 'Opção',
    copy: 'Copiar',
    edit: 'Editar',
    save: 'Salvar',
    cancel: 'Cancelar',
    notification: {
      save: 'Sucesso! Formulário salvo.',
    },
    drag: 'Arraste os campos aqui para criar o formulário',
    reviewFieldsModal: {
      title: 'Revise os campos do seu formulário',
      firstParagraph:
        'Inclua o campo ”e-mail do signatário” para extrair do formulário o e-mail de quem assinará. No caso de assinatura via WhatsApp, inclua também o campo ”Telefone do signatário".',
      secondParagraph:
        'Se você não precisa que o documento seja enviado para signatários diferentes, desconsidere a mensagem.',
      actions: {
        back: 'Voltar e corrigir',
        save: 'Avançar e salvar',
      },
    },
  },
  view: {
    flag: 'Pré-visualização',
    help: 'No modo de pré-visualização as respostas enviadas não serão salvas.',
    submit: 'Enviar respostas',
    generatingDocumentToSign:
      'O seu documento está sendo gerado. Em instantes você será redirecionado para a página de assinatura.',
    success: {
      title: 'Respostas enviadas!',
      subtitle: 'Estamos preparando um novo documento que em breve será enviado para assinatura.',
    },
    error: {
      default: 'Não conseguimos enviar as resposta corretamente',
      reload: 'Recarregar formulário',
      notFound: 'Nenhum formulário selecionado',
      empty: 'Formulário não contém campos',
    },
    security: 'Ambiente seguro Clicksign',
    validation: {
      invalidCep: 'CEP não é válido',
      cepNotFound:
        'CEP não localizado. Verifique se o CEP digitado está correto ou preencha os campos manualmente',
      filemaxsize: 'O tamanho do arquivo ultrapassa o permitido de %{max_size_file}mb',
      extension:
        'Formato não permitido, use um dos seguintes formatos: jpg, jpeg, png, pdf, doc e docx',
    },
    requiredFields: '* Campos obrigatórios',
    attachFilePdf: 'Somente são aceitos arquivos no formato .pdf',
    imageFile: 'Somente são aceitas imagens nesse campo.',
    selectOption: 'Selecione uma opção',
  },
  fields: {
    autofill: {
      linkedWith: 'Autopreenchido por',
      linkedWithField: 'Vinculado a chave',
      attributeType: 'Tipo de Campo',
    },
    cep: {
      label: {
        street: 'Logradouro',
        number: 'Número',
        complement: 'Complemento',
        neighborhood: 'Bairro',
        city: 'Cidade',
        federalUnit: 'Estado',
      },
      description: 'Adicione campos que serão autopreenchicos pelo CEP',
    },
    required: 'Campos obrigatórios',
  },
  file: {
    hint: 'Arquivos aceitos: jpg, jpeg, png, pdf, doc e docx',
    attachFileEnabled: {
      label: 'Assinar anexo',
      description: 'Será automaticamente incluído no documento para ser assinado.',
      hint: 'Marcando essa opção, o campo só aceitará arquivos no formato .pdf',
    },
  },
  image: {
    hint: 'Arquivos aceitos: jpg, jpeg e png',
  },
};

export default formBuilder;
