const fieldCreator = {
  required: 'Campo obligatorio',
  createField: 'Crear campo',
  labelPlaceholder: 'Nuevo campo',
  insertFieldOnTemplate: 'Inserta este campo en tu documento de plantilla',
  fieldAddSuccess: 'Campo agregado con éxito',
  previewForm: 'Previsualizar {form}º Formulario',
  orderFormFields: 'Ordenar campos del formulario',
  actions: {
    insertInTemplate: 'Insertar en la plantilla',
    move: 'Mover a {0} º formulario',
  },
  tabs: {
    form1: '1º Formulario',
    form2: '2º Formulario',
  },
  secondForm: {
    info1:
      'El segundo formulario se utiliza cuando tu documento requiere información de varias personas.',
    info2:
      'Ejemplo: El comprador completa los datos de registro y el vendedor completa la información del pedido.',
    info3:
      'Los campos designados para completar el segundo formulario se mostrarán en esta pestaña tan pronto como sean ingresados.',
  },
  label: {
    field: 'Campo',
    type: 'Escribir',
  },
  items: {
    email: 'Correo electrónico del firmante',
    whatsapp: 'Whatsapp del firmante',
    tel: 'Teléfono del firmante',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    file: 'Archivo adjunto',
    image: 'Imagen',
    cep: 'CEP',
    currency: 'Moneda',
    text: 'Texto corto',
    textarea: 'Texto largo',
    select: 'Desplegable',
    radioGroup: 'Solo respuesta',
    checkboxGroup: 'Múltiples respuestas',
    date: 'Fecha',
    header: 'Encabezado',
    paragraph: 'Párrafo',
    autofill: 'Autocompletar',
  },
  editFields: {
    helpBalloon: {
      label: 'Nota de ayuda',
      placeholder: '¿Se puede ver haciendo click en el ícono "?"',
    },
    helpText: {
      label: 'Texto de ayuda',
      placeholder: 'Texto de ayuda dentro del campo',
      defaultValue: 'Seleccionar una opción',
    },
    maxCharacters: {
      label: 'Máximo de caracteres',
    },
    numberOfLines: {
      label: 'Número de líneas',
    },
    imageInfo: {
      text: 'Archivos aceptados: jpg, jpeg y png',
    },
    attachFileEnabled: {
      label: 'Firmar archivo adjunto',
      placeholder: 'Se incluirá automáticamente en el documento a firmar.',
      help: 'Al marcar esta opción, el campo solo aceptará archivos en formato .pdf',
    },
    header: {
      label: 'Título',
      placeholder: 'Encabezado',
    },
    paragraph: {
      label: 'Contenido',
      placeholder: 'Párrafo',
    },
    other: {
      label: 'Habilitar "Otro"',
      placeholder: 'Permitir agregar una opción que no listada',
    },
    options: {
      addOption: 'Agregar opción',
      label: 'Opciones',
      default: {
        label: 'Opción en el formulario',
        value: 'Texto para documento',
      },
    },
    cepField: {
      fields: {
        street: 'Calle',
        number: 'Número',
        complement: 'Referencias',
        neighborhood: 'Colonia',
        city: 'Ciudad',
        federalUnit: 'Estado',
      },
    },
  },
  validations: {
    label: {
      alreadyExists: 'El nombre del campo ya existe en el formulario {form}',
      cepAlreadyExists: 'No se puede volver a agregar este campo',
    },
  },
  orderingModal: {
    title: 'Ordenar campos del formulario',
    description: 'Ordene los campos y cómo se mostrarán en el formulario.',
    order: 'Ordenar',
  },
  cepDeletionModal: {
    title: '¿Eliminar campo CEP?',
    description:
      'Al eliminar el campo CEP, los campos complementarios de dirección agregados también se eliminarán. ¿Desea continuar?',
    checkbox: 'Sí, deseo eliminar el campo CEP y sus complementos',
  },
};

export default fieldCreator;
