const document = {
  selectADocumentToPreview: 'Selecione um documento para visualizá-lo.',
  errorWhenPreview:
    'Houve um erro ao carregar seu documento. Tente baixar o documento ou visualizá-lo em outro navegador.',
  signedDocumentInProccessing:
    'O documento assinado está sendo processado, em instantes a página será atualizada.',
  signedDocumentDisponibleTip:
    'O documento assinado será exibido assim que o processo de assinatura estiver finalizado.',
  deadlineDateFormat: 'DD/MM/YYYY [às] HH:mm',
  deadline: 'Prazo',
  finishedAt: 'Finalizado em',
  status: {
    canceled: 'Cancelado',
    closed: 'Finalizado',
    running: 'Em processo',
  },
};

export default document;
