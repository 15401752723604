import { kebabCase, uniqueId } from 'lodash';

const getIdFromField = (field) => uniqueId(`${kebabCase(field.label)}`);

const mountFieldWithId = (fields) => {
  return fields.map((field) => {
    return {
      ...field,
      id: getIdFromField(field),
    };
  });
};

export default {
  SET_FIELDS(state, payload) {
    state.formFields1 = mountFieldWithId(payload.formFields1);
    state.formFields2 = mountFieldWithId(payload.formFields2);
  },
  UPDATE_FIELD(state, { value, index, form }) {
    Object.assign(state[`formFields${form}`][index], value);
  },
  DELETE_FIELD(state, { index, form }) {
    state[`formFields${form}`].splice(index, 1);
  },
  ADD_FIELD(state, { form, value }) {
    state[`formFields${form}`].push({ ...value, id: getIdFromField(value) });
  },
  ADD_FIELD_FROM_INDEX(state, { form, value, index }) {
    const fieldArray = state[`formFields${form}`];
    const position = index + 1;

    fieldArray.splice(position, 0, { ...value, id: getIdFromField(value) });
  },
  REORDER_FIELDS(state, { form, fields }) {
    state[`formFields${form}`] = fields;
  },
  SET_PREVENT_COPY(state, value) {
    state.preventCopy = value;
  },
  SET_SELECTED_FIELD_INDEX(state, { index, form }) {
    state.selectedFieldIndex = {
      index,
      form,
    };
  },
  DELETE_CEP_FIELD(state, { index, form }) {
    const selectedField = state[`formFields${form}`][index];
    const updatedFormFields = state[`formFields${form}`].filter((field, fieldIndex) => {
      const isSelectedCEPField = fieldIndex === index;
      const isLinkedField =
        (field.linkedWithField ?? field.linked_with_field) === selectedField.name;

      return !isSelectedCEPField && !isLinkedField;
    });

    state[`formFields${form}`] = updatedFormFields;
  },
};
