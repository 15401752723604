import { ENVELOPE_SETUP_MODES } from '@envelope/constants/envelope-setup-constants';
import actions from './actions';

export default {
  actions,
  namespaced: true,
  state: {
    message: '',
    subject: '',
    settings: {},
    links: {},
    isEditingSession: false,
    signatureWatchers: [],
    alreadyPooling: false,
    canPosition: false,
    positionSigns: [],
    setupMode: ENVELOPE_SETUP_MODES.DEFAULT,
    temporarySeals: [],
  },
  mutations: {
    SET_ENVELOPE_SETUP_MODE: (state, payload) => {
      state.setupMode = payload ?? ENVELOPE_SETUP_MODES.DEFAULT;
    },
    SET_TEMPORARY_SEALS: (state, payload) => {
      state.temporarySeals = payload ?? [];
    },
    SET_LINKS: (state, payload) => {
      state.links = { ...state.links, ...(payload || {}) };
    },
    SET_SETTINGS: (state, payload) => {
      state.settings = {
        ...state.settings,
        ...(payload ?? {}),
      };
    },
    SET_IS_EDITING_SESSION: (state, payload) => {
      state.isEditingSession = payload || false;
    },
    SET_SIGNATURE_WATCHERS: (state, payload) => {
      state.signatureWatchers = payload;
    },
    SET_ALREADY_POOLING: (state, payload) => {
      state.alreadyPooling = payload;
    },
    SET_CAN_POSITION: (state, payload) => {
      state.canPosition = !!payload;
    },
    SET_POSITION_SIGNS: (state, payload) => {
      state.positionSigns = payload ?? [];
    },
    SET_MESSAGE: (state, payload) => {
      state.message = payload ?? '';
      state.settings.defaultMessage = state.message;
    },
    SET_SUBJECT: (state, payload) => {
      state.subject = payload ?? '';
    },
  },
  getters: {
    isEditingSession: (state) => state.isEditingSession,
    getSettings: (state) => state.settings,
    getLinks: (state) => state.links,
    getSignatureWatchers: (state) => state.signatureWatchers,
    getAlreadyPooling: (state) => state.alreadyPooling,
    getCanPosition: (state) => state.canPosition,
    getPositionSigns: (state) => state.positionSigns,
    getTemporarySeals: (state) => state.temporarySeals,
    getMessage: (state) => state.message,
    getSubject: (state) => state.subject,
    getSetupMode: (state) => state.setupMode,
  },
};
