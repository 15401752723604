import document from './document';
import envelope from './envelope';

const guest = {
  document,
  envelope,
  signinOrSignup: 'Entrar',
  seeSignatures: 'Visualizar assinaturas',
  errors: {
    expired: 'Você não tem acesso a este documento, contate o remetente',
    accessDenied: 'Você não tem acesso ao documento assinado, contate o remetente',
  },
};

export default guest;
