import { computed, watch } from 'vue';
import { useCtxStore } from '@base/hooks/useCtxStore';

import { checkIsToggleEnabled } from '@base/utils/toggle-utils';

export const useToggle = (toggleName, callbackOrOpt, options) => {
  const hasCallback = callbackOrOpt && typeof callbackOrOpt === 'function';
  const _options = options || (!hasCallback && callbackOrOpt) || {};
  const { off, scope } = _options;
  const store = _options.store ?? useCtxStore();

  const isEnabled = computed(() =>
    checkIsToggleEnabled(toggleName, store.getters['featureFlags/getToggles'], { scope })
  );

  watch(
    isEnabled,
    () => {
      const shouldRunCallback = off ? !isEnabled.value : isEnabled.value;

      if (hasCallback && shouldRunCallback) callbackOrOpt();
    },
    { immediate: true }
  );

  return { isEnabled };
};
