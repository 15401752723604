const documentDetector = {
  capture: {
    title: {
      rg: {
        front: 'Frente do seu RG',
        back: 'Verso do seu RG',
      },
      cnh: {
        front: 'Frente da sua CNH',
        back: 'Verso da sua CNH',
      },
      any: {
        front: 'Frente do seu documento',
        back: 'Verso do seu documento',
      },
      error: 'Encontramos um problema',
      already: 'Foto já validada',
    },

    error: {
      front: 'Frente do documento não reconhecida\nTente outra vez',
      back: 'Verso do documento não reconhecido\nTente outra vez',
      expiredDocument: 'Documento vencido',
      invalidCpf: 'CPF do documento não é o mesmo que o informado para essa assinatura',
      changeDocument: 'Trocar documento',
    },

    loading: 'Enviamos a foto para análise\nAguarde um momento...',
    success: 'Foto tirada com sucesso!',
  },
};

export default documentDetector;
