const document = {
  selectADocumentToPreview: 'Seleccione un documento para visualizarlo.',
  errorWhenPreview:
    'Hubo un error al cargar su documento. Intente descargar el documento o visualizarlo en otro navegador.',
  signedDocumentInProccessing:
    'El documento firmado está siendo procesado, en instantes la página se actualizará.',
  signedDocumentDisponibleTip:
    'El documento firmado se mostrará tan pronto como se complete el proceso de firma.',
  deadlineDateFormat: 'DD/MM/YYYY [a las] HH:mm',
  deadline: 'Plazo',
  finishedAt: 'Finalizado en',
  status: {
    canceled: 'Cancelado',
    closed: 'Finalizado',
    running: 'En proceso',
  },
};

export default document;
