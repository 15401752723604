const removeDocumentFrom = (collection, documentKey) =>
  collection.filter((document) => document.key !== documentKey);

export default {
  SET_LINKS(state, payload) {
    state.links = {
      ...state.links,
      ...(payload || {}),
    };
  },
  SET_ANCESTORS(state, payload) {
    state.ancestors = payload || [];
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_SIGNERS(state, payload) {
    state.signers = payload || [];
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload || {};
  },
  SET_SIGNATURES(state, payload) {
    state.signatures = payload || [];
  },
  UPDATE_SIGNER(state, payload) {
    let groupIndex = null;
    let signerIndex = null;

    const hasFindSigner = state.signatures.some(({ signers }, gIndex) => {
      const index = signers.findIndex((signer) => signer.key === payload.key);
      if (index !== -1) {
        groupIndex = gIndex;
        signerIndex = index;
        return true;
      }

      return false;
    });

    if (!hasFindSigner) return;

    state.signatures[groupIndex].signers.splice(signerIndex, 1, {
      ...state.signatures[groupIndex].signers[signerIndex],
      ...payload,
    });
  },
  SET_CURRENT_GROUP(state, payload) {
    state.currentGroup = payload;
  },
  SET_DOCUMENTS(state, payload) {
    state.documents = payload || [];
  },
  SET_ENVELOPE(state, payload) {
    Object.assign(state, payload);
  },
  SET_EDIT_SESSION(state, payload) {
    state.editSession = payload;
  },
  SET_CONFIG(state, payload) {
    state.remainingDocumentsCount = payload.remainingDocumentsCount;
    state.maxDocumentContentSize = payload.maxDocumentContentSize;
    state.maxImageContentSize = payload.maxImageContentSize;
  },
  SET_FOLDER(state, payload) {
    state.folder = payload || {};
  },
  SET_UPLOADING_DOCUMENT(state, payload) {
    state.uploadingDocuments.unshift(payload);
  },
  REMOVE_UPLOADING_DOCUMENT: (state, documentKey) => {
    state.uploadingDocuments = removeDocumentFrom(state.uploadingDocuments, documentKey);
  },
  UPDATE_UPLOADING_DOCUMENT(state, document) {
    const documentIndex = state.uploadingDocuments.findIndex(({ key }) => key === document.key);
    state.uploadingDocuments[documentIndex].progress = document.progress;
    state.uploadingDocuments[documentIndex].posted = document.posted;
    state.uploadingDocuments[documentIndex].readyStamped = document.readyStamped;
  },
  UPDATE_DOCUMENT(state, document) {
    const documents = [...state.documents];
    let index;
    if (document.stampKey) {
      index = state.documents.findIndex(({ stampKey }) => stampKey === document.stampKey);
    } else {
      index = state.documents.findIndex(({ key }) => key === document.key);
    }
    documents[index] = document;
    state.documents = documents;
  },
  SET_DELETING_DOCUMENT: (state, payload) => {
    state.deletingDocuments.unshift(payload);
  },
  REMOVE_DELETING_DOCUMENT: (state, documentKey) => {
    state.deletingDocuments = removeDocumentFrom(state.deletingDocuments, documentKey);
  },
  SET_NEW_DOCUMENT: (state, payload) => {
    state.documents.unshift(payload);
  },
  REMOVE_DOCUMENT: (state, documentKey) => {
    state.documents = removeDocumentFrom(state.documents, documentKey);
  },
  SET_GROUPS: (state, payload) => {
    state.groups = payload || {};
  },
  SET_EMAIL_NOTIFIABLE: (state, payload) => {
    state.hasEmailNotifiable = payload;
  },
  SET_WHATSAPP_NOTIFIABLE: (state, payload) => {
    state.hasWhatsappNotifiable = payload;
  },
  SET_OPERATIONS: (state, payload) => {
    state.hasOperations = payload;
  },
  SET_DEFAULT_MESSAGE: (state, payload) => {
    state.defaultMessage = payload;
  },
  SET_BLOCKED: (state, payload) => {
    state.blockAfterRefusal = payload.blockAfterRefusal || false;
    state.refused = payload.refused || false;
  },
  SET_AUTO_SIGN: (state, payload) => {
    state.editSession = { ...state.editSession, autoSign: payload };
  },
};
