import Vue from 'vue';
import VueRouter from 'vue-router';
import qs from 'qs';
import { mapValues, attempt, isError } from 'lodash';
import { routes } from '@base/routes';
import { useEmitPageDataLayer } from '@/modules/base/hooks/useEmitPageDataLayer';
import store from '@/store';
import { camelizeKeys } from '@base/utils';
import { buildURLParams } from '@base/utils/build-url-params';
import { appcuesTrackNavigation } from '@/modules/base/utils/appcues';
import { sentry } from '@/plugins/sentry';
import { setLocale } from '@/locales';
import { usePosthog } from '@/modules/base/composables';

const shouldRegisterPageEvent = (to, from) => {
  const isFirstPageLoad = from?.name == null;
  const isChangingPath = from.path !== to.path;

  return !isFirstPageLoad && isChangingPath;
};

const router = new VueRouter({
  mode: 'history',
  parseQuery: qs.parse,
  stringifyQuery: buildURLParams,
  routes: [
    ...routes.filter((route) => route.path?.includes('/')),
    {
      path: '*',
      meta: { rails: true },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const layoutDataElement = document.getElementById('app');
  if (layoutDataElement) {
    const parseDatasetToProps = (dataset) =>
      mapValues(dataset, (value) => {
        const isParsableString = !isError(attempt(JSON.parse.bind(null, value)));
        return isParsableString ? JSON.parse(value) : value;
      });
    const parseKeys = (data) => {
      try {
        const dataObject = JSON.parse(data);
        return camelizeKeys(parseDatasetToProps(dataObject));
      } catch (error) {
        return data;
      }
    };

    const { accountData, configData, abTests, userData } = parseKeys(
      layoutDataElement.dataset?.layoutData
    );
    const isLoggedIn = accountData?.account?.id;

    attempt(() => {
      // TOGGLE_INTERNATIONALIZATION_ENABLED
      // TODO: Remove if condition and keep only its content
      if (accountData?.account?.toggles?.toggleInternationalizationEnabled) {
        setLocale(configData?.locale);
      }
    });

    await Promise.all([
      store.dispatch('info/setAccountData', accountData),
      store.dispatch('info/setUserData', userData),
      store.dispatch('info/setConfigData', configData),
      store.dispatch('info/setABTestsData', abTests),
      isLoggedIn && store.dispatch('subscription/fetchFunctionalities'),
      isLoggedIn && store.dispatch('incentives/fetchIncentives'),
    ]);
  }

  if (shouldRegisterPageEvent(to, from)) {
    const posthog = usePosthog();

    posthog.capturePageEvent(posthog.pageEvent.leave);
  }

  const isRails = to.meta.rails;
  const isToRefresh = to.meta?.refresh && to.meta?.refresh();

  if ((isRails || isToRefresh) && window.location.pathname !== to.path) {
    window.location.href = to.fullPath;
  } else {
    next();
  }
});

router.afterEach((to, from, failure) => {
  const { initDataLayerPush } = useEmitPageDataLayer();
  initDataLayerPush(to.fullPath);
  if (store.getters['info/getAccount'].id || store.getters['info/getAccount'].user) {
    if (process.env.NODE_ENV === 'production') appcuesTrackNavigation();
  }

  if (!failure && shouldRegisterPageEvent(to, from)) {
    Vue.nextTick(() => {
      const posthog = usePosthog();

      posthog.capturePageEvent(posthog.pageEvent.view);
    });
  }
});

router.onError((error) => {
  const errorToCapture = error instanceof TypeError ? new TypeError(error) : error;

  sentry.captureException(errorToCapture, {
    tags: {
      routerError: true,
    },
  });
});

Vue.use(VueRouter);

export default router;
