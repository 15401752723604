const routes = [
  {
    path: '/guest',
    component: () => import('@guest/layouts/GuestLayout.vue'),
    children: [
      {
        path: 'signers/:signerKey',
        component: () => import('@guest/views/OverviewCommonView.vue'),
        children: [
          {
            path: 'envelopes/:envelopeKey',
            name: 'GuestOverviewEnvelope',
            components: {
              details: () =>
                import('@guest/containers/OverviewEnvelopeContainer/OverviewEnvelopeContainer.vue'),
              mainContent: () =>
                import('@guest/containers/EnvelopeViewerContainer/EnvelopeViewerContainer.vue'),
            },
          },
          {
            path: 'documents/:documentKey',
            name: 'GuestOverviewDocument',
            components: {
              details: () =>
                import('@guest/containers/OverviewDocumentContainer/OverviewDocumentContainer.vue'),
              mainContent: () =>
                import('@guest/containers/DocumentViewerContainer/DocumentViewerContainer.vue'),
            },
          },
        ],
      },
    ],
  },
];

export default routes;
