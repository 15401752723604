// SELF_COUPON_ON_CHECKOUT
// TODO: Remove isToggleEnabled function
function isToggleEnabled(name) {
  return !window.localStorage
    ? undefined
    : JSON.parse(window.localStorage.getItem('account'))?.account?.toggles[name];
}

const changePlanSteps = [
  {
    name: 'confirmChangePlan',
    component: 'ChangePlanConfirm',
    icon: '',
    props: {
      plans: 'plans',
    },
    condition(isChangingPlan) {
      return isChangingPlan;
    },
  },
  {
    name: 'successChangePlan',
    component: 'ChangePlanSuccess',
    icon: '',
    props: {
      plans: 'plans',
      newPlanId: 'selectedPlanId',
    },
    condition(isChangingPlan) {
      return isChangingPlan;
    },
  },
];
export default {
  SELF_SERVICE_PLANS_STEPS: [
    {
      name: 'price',
      component: 'StepInfoPrice',
      icon: 'file-check',
      props: {
        plans: 'plans',
        account: 'account',
      },
    },
    ...changePlanSteps,
    {
      name: 'form',
      component: 'StepCustomerForm',
      icon: 'user',
      props: {
        plan: 'plan',
        goBackLink: 'links.plans',
        errors: 'errorsForm',
        billingCustomer: 'subscription.billingCustomer',
        loadingAddress: 'isLoadingAddress',
        validations: '$v.subscription',
        toggleSegmentRequiredEnabled: 'toggleSegmentRequiredEnabled',
      },
      condition(isChangingPlan) {
        return !isChangingPlan;
      },
      data: {
        billingCustomer: {
          accountAttributes: { segment: '' },
          common: {
            kind: 'company',
            zipCode: '',
            address: '',
            addressComplement: '',
            number: '',
            city: '',
            neighborhood: '',
            region: '',
            email: '',
            phoneNumber: '',
          },
          company: {
            name: '',
            tradingName: '',
            documentation: '',
          },
          person: {
            name: '',
            documentation: '',
          },
        },
      },
    },
    {
      name: 'checkout',
      // SELF_COUPON_ON_CHECKOUT
      // TODO: Remove ternary and keep only 'CheckoutForm' value
      component: isToggleEnabled('selfCouponOnCheckout') ? 'CheckoutForm' : 'CheckoutFormOld',
      icon: 'lock',
      action: 'handleSelfServicePlanCheckout',
      props: {
        plan: 'plan',
        isSubmiting: 'isSubmitingStep',
        billingCustomer: 'getSubscription.billingCustomer',
        paymentMethod: 'paymentMethod',
      },
      condition(isChangingPlan) {
        return !isChangingPlan;
      },
    },
    {
      name: 'resume',
      component: 'StepSubscriptionResume',
      props: {
        plan: 'plan',
        billingCustomer: 'getSubscription.billingCustomer',
        paymentMethod: 'paymentMethod',
      },
      condition(isChangingPlan) {
        return !isChangingPlan;
      },
    },
  ],
  CUSTOM_PLANS_STEPS: [
    {
      name: 'price',
      component: 'StepInfoPrice',
      icon: 'file-check',
      props: {
        plans: 'plans',
      },
    },
    {
      name: 'form',
      component: 'StepCustomerForm',
      icon: 'user',
      action: 'handleCustomPlanCheckout',
      props: {
        plan: 'plan',
        goBackLink: 'links.plans',
        errors: 'errorsForm',
        billingCustomer: 'subscription.billingCustomer',
        loadingAddress: 'isLoadingAddress',
        validations: '$v.subscription',
        toggleSegmentRequiredEnabled: 'toggleSegmentRequiredEnabled',
      },
      data: {
        billingCustomer: {
          accountAttributes: { segment: '' },
          common: {
            kind: 'company',
            zipCode: '',
            address: '',
            addressComplement: '',
            number: '',
            city: '',
            neighborhood: '',
            region: '',
            email: '',
            phoneNumber: '',
          },
          company: {
            name: '',
            tradingName: '',
            documentation: '',
          },
          person: {
            name: '',
            documentation: '',
          },
        },
      },
    },
    {
      name: 'resume',
      component: 'StepSubscriptionResume',
      props: {
        plan: 'plan',
        billingCustomer: 'getSubscription.billingCustomer',
        paymentMethod: 'paymentMethod',
      },
    },
  ],
  PLANS: [
    {
      id: '1',
      name: 'eventual',
      commercialName: 'eventual',
      category: 'signature',
      usersPerAccount: '1',
      monthDocuments: '0',
      documentPrice: '8,50',
      price: '0',
      color: 'brand-primary-300',
    },
    {
      id: '2',
      name: 'essencial',
      commercialName: 'essencial',
      category: 'signature',
      monthDocuments: '20',
      documentPrice: '1,95',
      usersPerAccount: '5',
      price: '39',
      color: 'brand-primary-300',
      icon: 'feather',
      hide: true,
    },
    {
      id: '3',
      name: 'profissional',
      commercialName: 'profissional',
      category: 'signature',
      monthDocuments: '70',
      documentPrice: '1,13',
      usersPerAccount: '8',
      price: '79',
      color: 'brand-primary-300',
      icon: 'briefcase',
      hide: true,
    },
    {
      id: '4',
      name: 'empresarial',
      commercialName: 'empresarial',
      category: 'signature',
      monthDocuments: '150',
      documentPrice: '0,99',
      usersPerAccount: '10',
      price: '149',
      color: 'brand-primary-300',
      icon: 'building',
      hide: true,
    },
    {
      id: '5',
      name: 'integration',
      commercialName: 'integration',
      category: 'custom',
      monthDocuments: '499',
      usersPerAccount: '15',
      price: '119',
      color: 'feedback-warning-400',
      icon: 'gear-complex-code',
      hide: true,
    },
    {
      id: '6',
      name: 'automation',
      commercialName: 'customized',
      category: 'custom',
      monthDocuments: '500',
      color: 'feedback-error-400',
      hide: true,
    },
    {
      id: '7',
      name: 'start',
      commercialName: 'start',
      category: 'signature',
      icon: 'rocket',
      color: 'brand-primary-300',
      usersPerAccount: '5',
      tiers: {
        start_20: {
          monthDocuments: '20',
          documentPrice: '3,00',
          price: '39',
        },
        start_50: {
          monthDocuments: '50',
          documentPrice: '2,70',
          price: '69',
          // TOGGLE_PLANS_TIER_50_INCENTIVES_ENABLED
          // TODO: Remove the line below
          promotionalPrice: '59',
        },
        start_100: {
          monthDocuments: '100',
          documentPrice: '2,50',
          price: '119',
        },
      },
    },
    {
      id: '8',
      name: 'plus',
      commercialName: 'plus',
      category: 'signature',
      icon: 'star',
      color: 'brand-primary-300',
      usersPerAccount: '10',
      tiers: {
        plus_20: {
          monthDocuments: '20',
          documentPrice: '4,90',
          price: '69',
        },
        plus_50: {
          monthDocuments: '50',
          documentPrice: '4,20',
          promotionalDiscount: '31',
          planToCompare: 'Plus 20',
          price: '119',
          // TOGGLE_PLANS_TIER_50_INCENTIVES_ENABLED
          // TODO: Remove the line below
          promotionalPrice: '99',
        },
        plus_100: {
          monthDocuments: '100',
          documentPrice: '4,00',
          promotionalDiscount: '20',
          planToCompare: 'Plus 50',
          price: '199',
        },
      },
    },
    {
      id: '9',
      name: 'avancado',
      commercialName: 'avancado',
      category: 'custom',
      color: 'brand-primary-300',
      icon: 'puzzle',
    },
  ],
  // TOGGLE_SMB_PRICING_TEST_ENABLED
  // TODO: Remover esses planos promocionais após a finalização do teste
  PROMO_PLANS: [
    {
      id: '10',
      name: 'start',
      commercialName: 'start',
      category: 'promo',
      icon: 'rocket',
      color: 'brand-primary-300',
      usersPerAccount: '5',
      tiers: {
        start_20: {
          monthDocuments: '20',
          documentPrice: '3,00',
          price: '39',
        },
        start_50: {
          monthDocuments: '50',
          documentPrice: '2,70',
          price: '59',
          fullPrice: '69',
        },
        start_100: {
          monthDocuments: '100',
          documentPrice: '2,50',
          price: '99',
          fullPrice: '119',
        },
      },
    },
    {
      id: '11',
      name: 'plus',
      commercialName: 'plus',
      category: 'promo',
      icon: 'star',
      color: 'brand-primary-300',
      usersPerAccount: '10',
      tiers: {
        plus_20: {
          monthDocuments: '20',
          documentPrice: '4,90',
          price: '69',
        },
        plus_50: {
          monthDocuments: '50',
          documentPrice: '4,20',
          promotionalDiscount: '31',
          planToCompare: 'Plus 20',
          price: '99',
          fullPrice: '119',
        },
        plus_100: {
          monthDocuments: '100',
          documentPrice: '4,00',
          promotionalDiscount: '20',
          planToCompare: 'Plus 50',
          price: '179',
          fullPrice: '199',
        },
      },
    },
    {
      id: '9',
      name: 'avancado',
      commercialName: 'avancado',
      category: 'custom',
      color: 'brand-primary-300',
      icon: 'puzzle',
    },
  ],
  CUSTOM_PLANS_OPTIONS: {
    challenger: [
      'Perda de vendas',
      'Falta de agilidade',
      'Processo custoso',
      'Perda de documentos (armazenamento físico)',
      'Problema Jurídico',
      'Outro',
    ],
    documentSystem: ['Sim', 'Não', 'Não sei informar'],
    documentModel: ['Sim', 'Às vezes', 'Não', 'Não sei informar'],
    functionality: ['WhatsApp', 'Integração por API', 'Fluxo por Formulário', 'Fluxo por Planilha'],
  },
  PLANS_CONFIRMATIONS_RESUME: {
    default: {
      titleBankSlip: 'subscription.resume.subtitleBankSlip',
      titleCreditCard: 'subscription.resume.subtitleCreditCard',
      descriptionBankSlip: 'subscription.resume.descriptionBankSlip',
      descriptionCreditCard: 'subscription.resume.descriptionCreditCard',
    },
    eventual: {
      titleBankSlip: 'subscription.resume.eventualSubtitleBankSlip',
      descriptionBankSlip: 'subscription.resume.eventualDescriptionBankSlip',
      titleCreditCard: 'subscription.resume.eventualSubtitleCreditCard',
      descriptionCreditCard: 'subscription.resume.eventualDescriptionCreditCard',
      helpLink: 'eventualCreditCardHelpLink',
    },
    custom: {
      title: 'subscription.resume.customSubtitle',
      description: 'subscription.resume.customDescription',
      actionButton: 'subscription.resume.goToHome',
    },
  },
  PAYMENT_METHODS: [
    {
      value: 'credit_card',
      text: 'Cartão de crédito',
      dataTestid: 'creditCardRadioButton',
    },
    {
      value: 'bank_slip',
      text: 'Boleto',
      dataTestid: 'bankSlipRadioButton',
    },
  ],
  PAYMENT_METHODS_VALUES: {
    creditCard: 'credit_card',
    bankSlip: 'bank_slip',
  },
  CREDIT_CARD_NETWORKS: ['visa', 'mastercard', 'elo', 'americanExpress', 'dinersClub', 'hipercard'],
  INTEGRATION_PRICING_RANGES: [
    {
      baseValue: 119,
      additionalDocumentValue: 4,
      maxDocuments: 40,
      rangeLimit: 80,
    },
    {
      baseValue: 279,
      additionalDocumentValue: 3.5,
      maxDocuments: 100,
      rangeLimit: 160,
    },
    {
      baseValue: 489,
      additionalDocumentValue: 3,
      maxDocuments: 200,
      rangeLimit: 471,
    },
  ],
  CUSTOM_PLANS: ['custom', 'fluxia', 'avancado'],
  PLANS_WITH_FREE_ADDONS: ['plus'],
  // TOGGLE_PLANS_TIER_50_INCENTIVES_ENABLED
  // TODO: Remove the line below
  PLANS_TO_SHOW_PROMOTION_INCENTIVES: ['eventual', 'plus_20', 'start_20'],
};
