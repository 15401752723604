import icpBrasil from './icp-brasil';
import liveness from './liveness';
import facialBiometrics from './facial-biometrics';
import documentDetector from './document-detector';
import refusalSignature from './refusal-signature';
import location from './location';
import voiceBiometrics from './voice-biometrics';

const steps = {
  icpBrasil,
  liveness,
  documentDetector,
  facialBiometrics,
  refusalSignature,
  location,
  voiceBiometrics,
};

export default steps;
