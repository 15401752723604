const links = {
  host: 'https://www.clicksign.com/es',
  support: '@:(links.host)/suporte/',
  contact: '@:(links.host)/contato/',
  legal: {
    useTerms: '@:(links.host)/termos-de-uso/',
    legalValidity: '@:(links.host)/validade-juridica/',
    privacyPolicy: '@:(links.host)/politica-de-privacidade/',
  },
  website: {
    whatsAppAcceptance: '@:(links.host)/aceite-via-whatsapp',
    planComparisonTable: '@:(links.host)/tabela-comparativa',
    // TOGGLE_SMB_PRICING_TEST_ENABLED
    // TODO: Remove campaingTerms link
    campaingTerms: '@:(links.host)/regulamento-preco',
    articles: {
      electronicDigitalSignature: '@:(links.host)/assunto/assinatura-eletronica-digital',
    },
  },
  help: {
    host: 'https://ajuda.clicksign.com/es-mx',
    whatsAppAcceptance: {
      howToSend: '@:(links.help.host)/article/861-como-enviar-um-aceite-via-whatsapp',
      models: '@:(links.help.host)/article/885-modelos-de-aceite',
      billing: '@:(links.help.host)/article/937-cobranca-aceite',
    },
    account: {
      config: '@:(links.help.host)/article/112-configuracoes-da-conta',
      accountAudit: '@:(links.help.host)/article/860-auditoria-da-conta',
      disabled: '@:(links.help.host)/sua-conta-foi-desativada-saiba-o-que-fazer',
      registration: '@:(links.help.host)/como-me-registro-en-clicksign',
      emailChange:
        '@:(links.help.host)/como-cambiar-mi-correo-electronico-de-acceso-a-la-plataforma',
      linkedAccounts: '@:(links.help.host)/obten-mas-informacion-sobre-cuentas-vinculadas',
      customize: '@:(links.help.host)/article/273-personalizacao',
      twoFactorAuth: {
        enable: '@:(links.help.host)/article/894-como-habilitar-a-verificacao-em-duas-etapas',
        apps: '@:(links.help.host)/article/893-quais-os-aplicativos-utilizados-para-o-duplo-fator-de-autenticacao',
      },
    },
    billing: {
      plans: '@:(links.help.host)/article/240-planos-da-clicksign',
      howWorks: '@:(links.help.host)/article/647-como-funcionam-cobrancas-na-clicksign',
      surplusConsumption: '@:(links.help.host)/article/943-consumo-excedente',
      invoiceNotReceived:
        '@:(links.help.host)/article/212-recebi-a-nota-fiscal-mas-nao-recebi-nenhum-boleto',
      documentCounting:
        '@:(links.help.host)/article/279-como-os-meus-documentos-sao-contabilizados',
      whatsAppSignature:
        '@:(links.help.host)/article/908-quanto-custa-enviar-um-documento-para-assinatura-via-whatsapp',
      facialbiometric: '@:(links.help.host)/quanto-custa-biometria-facial',
      tokenSms: '@:(links.help.host)/article/1026-quanto-custa-envio-token-sms',
    },
    docs: {
      whatsappSignature: '@:(links.help.host)/article/284-assinatura-via-whatsapp',
      customSender: '@:(links.help.host)/article/115-e-possivel-ter-um-remetente-customizado',
      howToSend: '@:(links.help.host)/como-enviar-documentos-para-firma',
      details: '@:(links.help.host)/article/817-detalhes-do-documento',
      sharedLocation: '@:(links.help.host)/article/1003-localizacao-compartilhada',
      changeOrderingAfterSending: '@:(links.help.host)/alterar-a-ordenacao-apos-envio',
      setup: {
        signatureOrder: '@:(links.help.host)/article/209-ordenar-assinaturas',
        signatureList: '@:(links.help.host)/article/255-como-criar-uma-lista-de-assinatura',
        settings: '@:(links.help.host)/article/257-como-definir-as-configuracoes-dos-documentos',
        observerUser:
          '@:(links.help.host)/agrega-a-quien-esta-acompanando-el-proceso-de-firma-observador',
        pageView: '@:(links.help.host)/article/951-como-configurar-visto-canto-pagina',
        rubric: '@:(links.help.host)/article/951-como-configurar-rubrica-canto-pagina',
        readingConfirmation:
          '@:(links.help.host)/article/944-configurar-confirmacao-leitura-de-clausulas',
        documentView:
          '@:(links.help.host)/article/945-configurar-confirmacao-visualizacao-documento',
        selectMessage: '@:(links.help.host)/article/258-como-utilizar-uma-mensagem',
      },
      auths: {
        token: {
          all: '@:(links.help.host)/firmar-documentos',
          whatsapp: {
            limit:
              '@:(links.help.host)/article/911-limite-de-tentativas-de-envio-de-token-via-whatsapp',
          },
        },
        handwritten: '@:(links.help.host)/autenticacion-por-firma-manuscrita',
        selfie: '@:(links.help.host)/article/543-autenticacao-por-selfie-com-documento',
        addressProof: '@:(links.help.host)/autenticacao-por-comprovante-de-endereco',
        officialDocument: '@:(links.help.host)/article/400-autenticacao-por-documento-oficial',
        liveness: '@:(links.help.host)/article/575-autenticacao-por-selfie-dinamica',
        pix: '@:(links.help.host)/article/503-autenticacao-por-pix',
        facialBiometrics: '@:(links.help.host)/article/755-autenticacao-por-biometria-facial',
        icpBrasil: '@:(links.help.host)/article/657-autenticacao-por-certificado-digital',
        rubric: '@:(links.help.host)/article/939-rubrica-eletronica',
        voiceBiometrics: '@:(links.help.host)/biometria-de-voz',
        available: '@:(links.help.host)/autenticaciones-disponibles',
        aditional: '@:(links.help.host)/article/615-como-utilizar-as-autenticacoes-adicionais',
      },
    },
    settings: {
      membership: {
        types: 'https://ajuda.clicksign.com/es-mx/tipos-de-usuarios-y-permisos',
        additionalPermissions:
          'https://ajuda.clicksign.com/uso-da-plataforma#usuários-e-permissões',
        financial: '@:(links.help.host)/article/405-como-configurar-um-usuario-financeiro',
        folderManagement: 'https://ajuda.clicksign.com/es-mx/gesti%C3%B3n-de-carpetas',
      },
      ipAccessLimit: '@:(links.help.host)/limite-de-acesso-ip',
      sso: '@:(links.help.host)/article/868-como-habilitar-sso',
      sessionTimeout: '@:(links.help.host)/tempo-limite-de-sessão',
    },
    reports: {
      consumption: '@:(links.help.host)/article/646-relatorio-de-consumo',
      documents: '@:(links.help.host)/article/321-relatorios-de-documentos',
      flows: '@:(links.help.host)/article/323-relatorios-de-fluxos',
      signers: '@:(links.help.host)/article/322-relatorio-de-signatarios',
      generate: '@:(links.help.host)/article/320-como-gerar-relatorios-em-minha-conta',
      emailsSent: '@:(links.help.host)/como-saber-si-los-documentos-fueron-entregados',
    },
    flows: {
      generateDocs: '@:(links.help.host)/article/261-como-gerar-documentos',
      variableSigner:
        '@:(links.help.host)/article/339-como-adicionar-signatario-variavel-fluxo-formulario',
      approvalProcess:
        '@:(links.help.host)/article/324-como-definir-um-processo-de-aprovacao-em-meu-fluxo',
      withForm: '@:(links.help.host)/article/244-fluxo-com-formulario',
      createForm: '@:(links.help.host)/article/243-como-criar-um-formulario',
      selectForm: '@:(links.help.host)/article/246-como-selecionar-um-formulario',
      folderAndDocName:
        '@:(links.help.host)/article/256-como-definir-nome-e-a-pasta-dos-documentos',
      spreadsheet: {
        info: '@:(links.help.host)/article/439-fluxo-com-planilha',
        model: '@:(links.help.flows.spreadsheet.info)#modelo',
        analysis: '@:(links.help.flows.spreadsheet.info)#analise',
        mapping: '@:(links.help.flows.spreadsheet.info)#mapeamento',
        send: '@:(links.help.flows.spreadsheet.info)#enviar',
        signers: '@:(links.help.flows.spreadsheet.info)#signatarios',
        name: '@:(links.help.flows.spreadsheet.info)#nome',
        spreadsheet: '@:(links.help.flows.spreadsheet.info)#planilha',
      },
      templates: {
        create: '@:(links.help.host)/article/121-como-criar-um-modelo-de-documento',
        select: '@:(links.help.host)/article/247-como-selecionar-um-modelo',
      },
      selectDocContent: '@:(links.help.host)/article/254-como-selecionar-o-conteudo-dos-documentos',
      enable: '@:(links.help.host)/article/259-como-ativar-o-fluxo',
      identify: '@:(links.help.host)/article/245-como-identificar-o-seu-fluxo',
    },
    envelope: {
      howToSend: '@:(links.help.host)/como-enviar-sobres-para-firma',
      whatChange: '@:(links.help.host)/o-que-muda-com-o-envelope',
      info: '@:(links.help.host)/article/1028-envelope',
    },
    signer: {
      signing: '@:(links.help.host)/article/122-como-assinar-um-documento',
      sharedLocation: '@:(links.help.host)/como-permitir-el-acceso-a-mi-ubicacion',
      digitalCertificate: '@:(links.help.host)/certificado-digital',
      cloudDigitalCertificate: '@:(links.help.host)/article/973-certificado-digital-em-nuvem',
      signatureArea: '@:(links.help.host)/article/866-area-de-assinatura',
    },
    management: {
      deadlinesControl: '@:(links.help.host)/control-de-plazos',
    },
    other: {
      betaFunctionality: '@:(links.help.host)/article/319-o-que-e-uma-funcionalidade-beta',
      api: '@:(links.help.host)/api',
    },
    signature: {
      reusableManuscript: '@:(links.help.host)/reutilizando-assinatura-manuscrita',
    },
  },
};

export default links;
