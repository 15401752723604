import { config } from '@base/constants';
import { sentry } from '@/plugins/sentry';

const MAX_RETRIES = config.isDev ? 1 : 60;

export function usePosthog(options = { maxRetries: MAX_RETRIES }) {
  const defaultErrorMessage = 'Posthog instance is not initialized';
  const pageEvent = {
    leave: '$pageleave',
    view: '$pageview',
  };

  function developmentDebugger(eventName, eventValue) {
    // eslint-disable-next-line no-console
    console.group(`Posthog event "${eventName}"`);
    // eslint-disable-next-line no-console
    console.table(eventValue);
    // eslint-disable-next-line no-console
    console.groupEnd();
  }

  function isPosthogInitialized() {
    return !!window.posthog?.config?.api_host;
  }

  function initPosthog(maxRetries = MAX_RETRIES) {
    let counter = 0;

    return new Promise((resolve, reject) => {
      function checkPosthog() {
        if (counter >= maxRetries) {
          reject(new Error(defaultErrorMessage));

          return;
        }

        if (isPosthogInitialized()) {
          resolve(true);

          return;
        }

        counter += 1;
        setTimeout(checkPosthog, 250);
      }

      checkPosthog();
    });
  }

  async function capture(event, value) {
    try {
      await initPosthog(options?.maxRetries);

      return window.posthog.capture(event, value);
    } catch (err) {
      const isKnownError = err.message === defaultErrorMessage;

      if (!config.isDev && !isKnownError) {
        sentry.captureException(err);
      }

      if (config.isDev) {
        developmentDebugger(event, value);
      }

      return null;
    }
  }

  function capturePageEvent(event) {
    try {
      if (isPosthogInitialized()) {
        return window.posthog.capture(event);
      }
    } catch (err) {
      if (config.isDev) {
        developmentDebugger(event);
      } else {
        sentry.captureException(err);
      }
    }

    return null;
  }

  return {
    capture,
    capturePageEvent,
    pageEvent,
  };
}
