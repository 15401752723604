<script setup>
import { computed, ref, onMounted } from 'vue';

import { FormFlowApprovalRequestsTable } from '@flow/components/form-flow';
import BaseConfirmationModal from '@base/components/common/BaseConfirmationModal.vue';
import FlowDisabledContainer from '@flow/containers/disabled/FlowDisabledContainer.vue';
import { ComplianceModal, ComplianceHeader } from '@flow/components/compliance';

import { FEATURES } from '@base/constants';
import { useFunctionalities, useGA, useListenForGaEvents, useToast } from '@base/hooks';
import { useStore } from '@/store';
import { useI18n } from '@/locales';

const store = useStore();
const { t } = useI18n();
const toast = useToast();
const { isEnabledAndNotBlockedByAdmin } = useFunctionalities();

const isLoadingProcessList = ref(false);
const isLoadingApproval = ref(false);
const showComplianceModal = ref(false);
const confirmationModalToShow = ref({});

const isFlowComplianceEnabled = computed(() => isEnabledAndNotBlockedByAdmin(FEATURES.FORM_FLOW));
const pagination = computed(() => store.getters['flowProcess/getPagination']);
const flowProcesses = computed(() => store.getters['flowProcess/getFlowProcesses']);

useListenForGaEvents(document.body, 'automation-approval-app');

const updateList = async () => {
  isLoadingProcessList.value = true;
  await store.dispatch('flowProcess/getFlowProcessList', window.location.href);
  await store.dispatch('flowProcess/fetchFlowsFilter');
  isLoadingProcessList.value = false;
};

const handleItemClick = (process) => {
  store.commit('flowProcess/SET_SELECTED_PROCESS', process);
  showComplianceModal.value = true;
};

const setProcessAction = (action) => {
  confirmationModalToShow.value = {
    type: action,
    title: t(`compliance.confirmAction.${action}`),
    text: t(`compliance.confirmAction.${action}Info`),
    action: t(`compliance.comfirmModal.${action}`),
  };
};

const handleConfirmationModalClose = (type) => {
  confirmationModalToShow.value = {};
  useGA('event', `button-modal-${type}-cancel-click`, 'automation-approval-app');
};

const handleConfirmationModalConfirm = async (type) => {
  useGA('event', `button-modal-${type}-advance-click`, 'automation-approval-app');

  isLoadingApproval.value = true;
  let response;
  if (type === 'approve') {
    response = await store.dispatch('flowProcess/approveProcess');
  } else if (type === 'disapprove') {
    response = await store.dispatch('flowProcess/disapproveProcess');
  }
  toast.success(response.message);
  isLoadingApproval.value = false;
  confirmationModalToShow.value = {};
  showComplianceModal.value = false;
  updateList();
};

onMounted(updateList);
</script>

<template>
  <div>
    <div
      v-if="isFlowComplianceEnabled"
      :class="$style.container"
      data-testid="flowComplianceContainer"
    >
      <ComplianceHeader :loading="isLoadingProcessList" />
      <XCard
        elevation="low"
        :class="$style.card"
      >
        <div
          v-if="flowProcesses?.length === 0 && !isLoadingProcessList"
          :class="$style.noDataPlaceholder"
          data-testid="emptyContainer"
        >
          <XIcon
            size="2x"
            icon="clipboard-check"
            :class="$style.emptyIcon"
          />

          <h4 :class="[$style.title, $style.titleEmpty]">
            {{ $t('compliance.empty.title') }}
          </h4>

          <p :class="$style.descriptionEmpty">
            {{ $t('compliance.empty.description') }}
          </p>
        </div>

        <template v-else>
          <FormFlowApprovalRequestsTable
            :items="flowProcesses"
            :loading="isLoadingProcessList"
            data-testid="approvalRequestsTable"
            @item-click="(item) => handleItemClick(item)"
          />
          <XPagination
            v-if="pagination.pageCount > 1"
            :class="$style.paginationWrapper"
            align="center"
            :next-text="$t('general.action.next')"
            :prev-text="$t('general.action.previous')"
            :total-pages="pagination.pageCount"
            data-testid="paginationContainer"
          />
        </template>
      </XCard>
    </div>
    <FlowDisabledContainer
      v-else
      type="default"
      data-testid="flowDisabledContainer"
    />

    <ComplianceModal
      :show="showComplianceModal"
      data-testid="formFlowComplianceModal"
      @set-process-action="setProcessAction"
      @close="showComplianceModal = false"
    />

    <BaseConfirmationModal
      reverse-actions
      :show-modal="!!confirmationModalToShow.type"
      :title="confirmationModalToShow.title"
      :action="confirmationModalToShow.action"
      :disable-action="isLoadingApproval"
      :is-loading="isLoadingApproval"
      data-testid="baseConfirmationModal"
      @close="handleConfirmationModalClose(confirmationModalToShow.type)"
      @confirm="handleConfirmationModalConfirm(confirmationModalToShow.type)"
    >
      {{ confirmationModalToShow.text }}
    </BaseConfirmationModal>
  </div>
</template>

<style lang="scss" module>
.container {
  padding: var(--space-large);

  .card {
    .emptyIcon {
      color: var(--color-brand-primary-400);
    }

    .titleEmpty {
      margin: var(--space-regular) 0 0 0;
    }

    .descriptionEmpty {
      color: var(--color-gray);
    }

    .paginationWrapper {
      margin-top: var(--space-medium);
    }
  }

  .noDataPlaceholder {
    text-align: center;

    img {
      margin-bottom: var(--space-small-xx);
    }

    .title {
      margin: 0;
      font-weight: var(--font-weight-bold);
    }
  }
}
</style>
