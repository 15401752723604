<template>
  <div>
    <FormFlowShowSkeleton v-if="loading" />
    <div
      v-else
      :class="$style.container"
      data-testid="formFlowShowContainer"
    >
      <div :class="$style.header">
        <ButtonBack />
        <XButton
          v-if="formFlow.enabled"
          design="text"
          data-testid="disableButton"
          @click="openModal(formFlowDisableModal)"
        >
          {{ $t('flowModule.show.disableForEdit') }}
        </XButton>
        <XButton
          v-else
          design="text"
          :to="formFlow.links.edit"
          data-testid="editButton"
        >
          {{ $t('flowModule.show.editToActivate') }}
        </XButton>
      </div>
      <div :class="$style.titleContainer">
        <h1 :class="$style.title">Informações do fluxo</h1>
        <XBadge
          v-if="formFlow.enabled"
          icon="check-circle"
          color="feedback-success"
          data-testid="enableBadge"
        >
          {{ $t('flowModule.show.activated') }}
        </XBadge>
        <XBadge
          v-else
          icon="minus-octagon"
          data-testid="disableBadge"
        >
          {{ $t('flowModule.show.deactivated') }}
        </XBadge>
      </div>
      <XTabs
        :items="[
          {
            name: 'config',
            title: $t('flowModule.show.tabs.config'),
          },
          {
            name: 'fields',
            title: $t('flowModule.show.tabs.fields'),
            disabled: !hasDataMappings,
          },
        ]"
        :active-tab="tabActive"
        data-testid="formFlow"
        :class="$style.tabs"
        @change="handleTabActive"
      />
      <XTabsPanel
        tab-name="config"
        :active-tab="tabActive"
      >
        <FormFlowShowGeneralInfo
          :class="$style.content"
          :form-flow-name="formFlow?.name"
          :first-form-name="formFlow?.dataSources?.[0]?.name"
          :second-form-name="formFlow?.dataSources[1]?.name"
          :second-form-email="formFlow?.dataSources[0]?.secondEmailSettings?.email"
          :template-name="formFlow?.template?.name"
          :compliance-process="formFlow?.complianceProcess"
          :membership-name="getMembershipName"
          :flow-memberships="formFlow?.flowMemberships"
        />
        <template v-if="formFlow.flowLists.length + formFlow.flowSigners.length > 0">
          <div :class="$style.signerHeader">
            <h4>{{ $t('flowModule.show.signerInfo.title') }}</h4>
            <XBadge
              v-if="formFlow.sequenceEnabled"
              color="feedback-success"
            >
              {{ $t('flowModule.show.ordernationEnabled') }}
            </XBadge>
            <XBadge v-else>
              {{ $t('flowModule.show.ordernationDisabled') }}
            </XBadge>
          </div>
          <XCard
            v-if="formFlow.sequenceEnabled"
            elevation="low"
            :class="[$style.content, $style.signerInfo]"
          >
            <div
              v-for="(group, index) in formFlow.groups"
              :key="index"
            >
              <div :class="$style.groupTitle">
                <h6>Grupo {{ index }}</h6>
                <div :class="$style.line"></div>
              </div>
              <FormFlowShowSignerInfo
                v-for="(signer, signerIndex) in group"
                :key="signerIndex + index"
                :signer="signer"
                :order="Number(signerIndex) + Number(index)"
                :flow-signer="!!signer.fieldId || !!signer.formFieldWhatsappId"
              />
            </div>
          </XCard>
          <XCard
            v-else
            elevation="low"
            :class="[$style.content, $style.signerInfo]"
          >
            <FormFlowShowSignerInfo
              v-for="(signer, index) in formFlow.flowLists"
              :key="`signer${index}`"
              :signer="signer"
              :order="index + 1"
              :flow-signer="!!signer.fieldId || !!signer.formFieldWhatsappId"
            />
            <FormFlowShowSignerInfo
              v-for="(signer, index) in formFlow.flowSigners"
              :key="`flowSigner${index}`"
              :signer="signer"
              :order="index + 1"
              :flow-signer="!!signer.fieldId || !!signer.formFieldWhatsappId"
            />
          </XCard>
        </template>
        <FormFlowStorageFileInfo
          v-if="formFlow.documentName.length + formFlow.path.length > 0"
          :document-name="formFlow.documentName"
          :path="formFlow.path"
        />
        <FormFlowShowConfigInfo
          :deadline-in="formFlow.deadlineIn"
          :remind-interval="formFlow.remindInterval"
          :locale="formFlow.locale"
          :auto-close="formFlow.autoClose"
        />
        <template v-if="formFlow.message">
          <h4>{{ $t('flowModule.show.message') }}</h4>
          <XCard elevation="low">
            <p :class="$style.message">{{ formFlow.message }}</p>
          </XCard>
        </template>
      </XTabsPanel>
      <XTabsPanel
        tab-name="fields"
        :active-tab="tabActive"
      >
        <FormFlowShowMappingInfo :data-mappings="dataMappings" />
      </XTabsPanel>
    </div>
    <FormFlowDisableModal
      :show-modal="isModalActive(formFlowDisableModal)"
      :has-pending-processes="formFlow.pendingProcesses"
      data-testid="formFlowDisableModal"
      @go-to-processes="handleGoToProcesses"
      @close="closeModal(formFlowDisableModal)"
      @disable="handleDisable"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref } from 'vue';
import { useStore } from '@/store';
import { useModal, useToast, useGA } from '@base/hooks';
import { useI18n } from '@/locales';
import {
  FormFlowShowGeneralInfo,
  FormFlowShowSignerInfo,
  FormFlowStorageFileInfo,
  FormFlowShowConfigInfo,
  FormFlowShowSkeleton,
  FormFlowShowMappingInfo,
  FormFlowDisableModal,
} from '@flow/components/form-flow';
import ButtonBack from '@/components/button/ButtonBack.vue';

export default defineComponent({
  name: 'FormFlowShowView',
  components: {
    FormFlowShowGeneralInfo,
    FormFlowShowSignerInfo,
    FormFlowStorageFileInfo,
    FormFlowShowConfigInfo,
    FormFlowShowSkeleton,
    FormFlowDisableModal,
    FormFlowShowMappingInfo,
    ButtonBack,
  },
  setup() {
    const store = useStore();
    const loading = ref(true);
    const { isModalActive, openModal, closeModal } = useModal();
    const formFlowDisableModal = 'form-flow-disable-modal';
    const toast = useToast();
    const { t } = useI18n();
    const tabActive = ref('config');

    const formFlow = computed(() => store.getters['formFlow/getFormFlow']);
    const dataMappings = computed(() => store.getters['formFlow/getDataMappings']);
    const hasDataMappings = computed(() => dataMappings.value.length > 0);

    const getMembershipName = computed(() => {
      return formFlow.value.memberships.filter(
        (membership) => formFlow.value.membershipId === membership.id
      )[0].name;
    });

    const handleGoToProcesses = () => {
      window.open(formFlow.value.links.processes, '_self');
    };

    const fetchFormFlow = async () => {
      loading.value = true;
      await store.dispatch('formFlow/fetchFormFlow', { link: window.location.href });
      await store.dispatch('formFlow/fetchFormFlowDataMappings');
      loading.value = false;
    };

    const handleDisable = async () => {
      await store.dispatch('formFlow/bulkDisableFormFlow', { ids: [formFlow.value.id] });
      const message = t('flowModule.list.notifications.disable');
      toast.success(message);
      closeModal(formFlowDisableModal);
      fetchFormFlow();
    };

    const handleTabActive = (tab) => {
      const gaEvents = { fields: 'tab-view-contents-flow', config: 'tab-view-configs-flow' };
      useGA('event', gaEvents[tab.name], 'automation-form-flows-app');
      tabActive.value = tab.name;
    };

    onMounted(async () => {
      await fetchFormFlow();
      await store.dispatch('formFlow/setSelectedFormFlow', formFlow.value);
    });

    return {
      formFlow,
      getMembershipName,
      loading,
      formFlowDisableModal,
      dataMappings,
      tabActive,
      hasDataMappings,
      handleGoToProcesses,
      isModalActive,
      openModal,
      closeModal,
      handleDisable,
      handleTabActive,
    };
  },
});
</script>

<style lang="scss" module>
.container {
  padding: var(--space-large);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-regular);
  }

  .titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-regular);
    gap: var(--space-small-xx);

    .title {
      margin-bottom: 0;
      color: var(--color-brand-primary-600);
    }
  }

  .signerHeader {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-regular);
    gap: var(--space-small-xx);

    h4 {
      margin-bottom: 0px;
    }
  }

  .content {
    margin-bottom: var(--space-large-x);
  }

  .signerInfo {
    display: flex;
    flex-direction: column;
    gap: var(--space-large);

    .groupTitle {
      display: flex;
      align-items: center;
      margin-bottom: var(--space-regular);
      gap: var(--space-small-xx);
      color: var(--color-neutral-600);

      > h6 {
        margin-bottom: 0px;
      }

      .line {
        flex: 1;
        height: 1px;
        background-color: var(--color-neutral-400);
      }
    }
  }

  .message {
    margin-bottom: 0;
  }

  @media (max-width: 576px) {
    padding: var(--space-regular);
  }
}

.tabs {
  margin-top: var(--space-medium);
  margin-bottom: var(--space-regular);
}
</style>
