import { axios } from '@/plugins';

const fetchFormFlows = async ({ getters, commit }, params) => {
  try {
    commit('SET_LOADING', true);
    const { data } = await axios.get(getters.getLinks.self, {
      params: {
        perPage: 10,
        ...params,
      },
    });

    commit('SET_FORM_FLOWS', data.flows);
    commit('SET_PAGINATION', data.pagination);
    commit('SET_TOTAL_ITEMS', data.totalItems);
    commit('SET_FLOWS_LINKS', data.links);
    commit('SET_LOADING', false);
  } catch (err) {
    throw err;
  }
};

const createFormFlow = async ({ rootGetters }, payload = {}) => {
  try {
    const response = await axios.post(rootGetters['info/getLinks'].flows, payload);

    return response;
  } catch (err) {
    throw err;
  }
};

const bulkDuplicateFormFlow = async ({ state }, { ids }) => {
  try {
    const url = state.flowsLinks.duplicate;

    await axios.post(url, { ids });
  } catch (error) {
    throw error;
  }
};

const getFormFlowPermissions = async ({ state, commit }) => {
  try {
    commit('SET_LOADING', true);
    const {
      data: { flow },
    } = await axios.get(state.selectedFormFlow.links.permissions);
    commit('SET_LOADING', false);
    commit('SET_FLOW_PERMISSIONS', flow);
  } catch (error) {
    throw error;
  }
};

const setFormFlowPrivacy = async ({ dispatch, state }, payload) => {
  try {
    const url = state.selectedFormFlow.links.privacy;
    await axios.patch(url, payload);
    dispatch('fetchFormFlows');
  } catch (error) {
    throw error;
  }
};

const bulkDisableFormFlow = async ({ dispatch, state }, { ids }) => {
  try {
    const url = state.flowsLinks.disable;

    await axios.put(url, { ids });
    dispatch('fetchFormFlows');
  } catch (error) {
    throw error;
  }
};

const removeFromFolder = async ({ dispatch }, { url, flows }) => {
  const flowFormIds = flows.map((flow) => flow.id);
  await axios.patch(url, { flowFormIds });

  dispatch('fetchFormFlows');
};

const bulkDeleteFormFlow = async ({ state }, { ids }) => {
  try {
    const url = state.flowsLinks.destroy;

    await axios.delete(url, { data: { ids } });
  } catch (error) {
    throw error;
  }
};

const setSelectedFormFlow = async ({ commit }, flow) => {
  commit('SET_SELECTED_FORM_FLOW', flow);
};

const resetSelectedFormFlow = async ({ commit }) => {
  commit('SET_SELECTED_FORM_FLOW', {});
};

const fetchFormFlowData = async ({ getters, commit }) => {
  try {
    const { self } = getters.getLinks;
    const { data } = await axios.get(self);

    commit('SET_LINKS', data.links);
    commit('SET_FORM_FLOW', data.flow);

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchFormFlow = async ({ commit }, { link }) => {
  const response = await axios.get(link);
  commit('SET_FORM_FLOW', response.data.flow);
};

const fetchFormFlowDataMappings = async ({ getters, commit }) => {
  const {
    data: { dataMappings },
  } = await axios.get(getters.getFormFlow.links.dataMappings);
  commit('SET_DATA_MAPPINGS', dataMappings);
};

const bulkVerifyPendingProcesses = async ({ state, commit }, { ids, link }) => {
  const params = { ids };
  const url = link ?? state.flowsLinks.verifyPendingProcesses;
  const {
    data: {
      hasPendingProcesses,
      hasWaitingSecondFormProcesses,
      pendingProcesses,
      waitingSecondFormProcesses,
      links,
    },
  } = await axios.get(url, { params });

  commit('SET_HAS_PENDING_PROCESSES', hasPendingProcesses);
  commit('SET_HAS_WAITING_SECOND_FORM_PROCESSES', hasWaitingSecondFormProcesses);
  commit('SET_PENDING_PROCESSES', pendingProcesses);
  commit('SET_WAITING_SECOND_FORM_PROCESSES', waitingSecondFormProcesses);
  commit('SET_VERIFY_PENDING_PROCESSES_LINKS', links);
};

const saveConfigFormFlow = async ({ commit }, { url, payload }) => {
  const {
    data: { flow },
  } = await axios.patch(url, payload);
  commit('SET_FORM_FLOW', flow);
};

const saveSecondFormConfig = async (_, payload) => {
  const { accountId, flowId, dataSourceId } = payload;
  const url = `/accounts/${accountId}/flows/${flowId}/data_sources/${dataSourceId}`;
  await axios.patch(url, payload);
};

const fetchMoveFolders = async ({ getters, commit }, { folderName, excludeFolderId }) => {
  const url = getters.getFlowsLinks.filterFolders;
  const nextPage = (getters.getMoveFoldersPagination.page ?? 0) + 1;
  const params = {
    page: nextPage,
    name: folderName,
    excludeFolderId,
  };
  const {
    data: {
      filters: { folders, pagination },
    },
  } = await axios.get(url, { params });

  commit('SET_MOVE_FOLDERS', [...getters.getMoveFolders, ...folders]);
  commit('SET_MOVE_FOLDERS_PAGINATION', pagination);
};

const resetMoveFolders = ({ commit }) => {
  commit('SET_MOVE_FOLDERS', []);
  commit('SET_MOVE_FOLDERS_PAGINATION', {});
};

const abTestChangeFlowListingView = async ({ rootGetters }, viewOption) => {
  const url = rootGetters['info/getLinks'].changeFlowListingView;

  if (!url) return;

  const payload = { abTestGroupChangeFlowListingView: viewOption };

  await axios.patch(url, payload);
};

export default {
  fetchFormFlows,
  createFormFlow,
  getFormFlowPermissions,
  setFormFlowPrivacy,
  setSelectedFormFlow,
  resetSelectedFormFlow,
  fetchFormFlowData,
  fetchFormFlow,
  fetchFormFlowDataMappings,
  saveConfigFormFlow,
  saveSecondFormConfig,
  removeFromFolder,
  bulkDisableFormFlow,
  bulkVerifyPendingProcesses,
  bulkDeleteFormFlow,
  bulkDuplicateFormFlow,
  fetchMoveFolders,
  resetMoveFolders,
  abTestChangeFlowListingView,
};
